
import { Tooltip as Tip,  Form,  } from "antd";
import { Table } from "antd";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_HEADER, get_audit_plan_by_project, BASE_DOCUMENT } from "../config";


const ClientAuditPlan = ({project_status}) => {
  const { id } = useParams();
  console.log("id:", id);

  console.log("idssssssss:", project_status);
  const [dataSource, setDataSource] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [loader, setLoader] = useState(false);


  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    setLoadData(true);
  };


	const columns = [
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					S.No
				</span>
			),
			dataIndex: "proposal_id",
			width: 60,
			render: (text, record, index) => {
				const pageIndex = (pagination.current - 1) * pagination.pageSize;
				return pageIndex + index + 1;
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Audit Plan
				</span>
			),
			width: 180,

			render: (text, record) => {
				return (
                    <>
                    <a target="_blank" href={`${BASE_DOCUMENT}/projects/${record.project_id}/${record.audit_plan}`}>
                                      {record.audit_plan}
                    </a>
                    </>
				);
			},
			sorter: (record1, record2) => {
				return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Remarks
				</span>
			),
			width: 120,
			ellipsis: true,
			// dataIndex: "project_name",
			render: (text, record) => {
				return (
				
					<Tip title={record.remarks}>
					<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
					  {record.remarks}
					</span>
				  </Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
			}
		},
		
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Shared By
				</span>
			),
			width: 120,
			ellipsis: true,

			render: (text, record) => {
				return (
				
					<Tip title={record.user_name}>
					            <span className="text-capitalize font14px text-ellipsis">
								{record.user_name}
							  </span>
				    </Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
			}
		},
		{
            title: (
              <span className="text-capitalize textcolumntitle font14px  fw-bold">
                Date
              </span>
            ),
            width: 80,
            render: (text, record) => {
              const formatDate = (dateString) => {
                const date = new Date(dateString);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
              };
          
              return (
                <span className="text-capitalize font14px">
                  {formatDate(record.created_at)}
                </span>
              );
            },
            sorter: (record1, record2) => {
              return new Date(record1.created_at) - new Date(record2.created_at);
            }
          }
          
	];

  const allData = async () => {
    try {
      setLoader(true);

      const response = await axios.get(
        `${get_audit_plan_by_project}/${id}`,
        API_HEADER
      );
      console.log("responseresponse",response)
      setDataSource(response.data.records);
      setLoadData(false);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.count,
      }));
      setLoader(false);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    allData();
  }, [loadData]);


  return (
    <>
      <div className="border-2 border border-light-subtle p-0 rounded-3 mt-2">
        <div className="row border-0">
          <div className="col-12 ">
            <p className="text-black text-capitalize mx-2 font20px fw-bold p-2 rounded-top-3">
              Audit Plan List
            </p>
          </div>
        </div>

        <Table
          scroll={{ x: 1000 }}
          className="border-1 border ronded-0 border-subtle-light px-0 m-3"
          columns={columns}
          dataSource={dataSource}
          rowKey="proposal_id"
          pagination={pagination}
          onChange={handleTableChange}
          loading={loader}
          bordered
        />
      </div>
    </>
  );
};

export default ClientAuditPlan;


