import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import '../../src/App.css'
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";



import {
  get_project_details_url,
  API_HEADER,
  get_assesment_url,
  BASE_DOCUMENT,
  at_desk_review_url,
  dvr_report_url, client_tr_round_url
} from "../config";


const ClientTechReview = () => {
  const { id } = useParams();
  const navigate = useNavigate()

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size



  const [program, setProgram] = useState([]);
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const [remarkTR, setRemarkTR] = useState("");
  const [remarkTL, setRemarkTL] = useState("");
  const [remarkAT, setRemarkAT] = useState("");
  const [remarkCTR, setRemarkCTR] = useState("");
  const [trStatus, setTrStatus] = useState("");
  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');
  const [file5, setFile5] = useState('');
  const [file6, setFile6] = useState('');
  const [fileDVRSizeError, setFileDVRSizeError] = useState(false);
  const [filePDDSizeError, setFilePDDSizeError] = useState(false);
  const [fileRRSizeError, setFileRRSizeError] = useState(false);
  const [filetechnicalreviewsheetSizeError, setFiletechnicalreviewsheetSizeError] = useState(false);
  const [fileCpaSizeError, setFileCpaSizeError] = useState(false);
  const [fileERSizeError, setFileERSizeError] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [technicalReviewSheetName, setTechnicalReviewSheetName] = useState(null);
  const [technicalReviewSheet, setTechnicalReviewSheet] = useState("");
  const [dvrName, setDVRName] = useState(null);
  const [dvr, setDvr] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");

  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [earthoodReportName, setEarthoodReportName] = useState(null);
  const [earthoodReport, setEarthoodReport] = useState("");

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);


  const [formData, setFormData] = useState({
    nobs_document: "",
    audit_document: "",
    visit_date: "",
  });


  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { teams } = response.data;
      setTeamList(teams);
      setProgram(record.program);
      setTrStatus(record.tr_status);


      setRemarkTL(record.tr_tl_remarks);
      setRemarkAT(record.tr_at_remarks);

      setRemarkCTR((record.tr_status == 4) ? record.tr_client_remarks = "" : record.tr_client_remarks)
      setAction(record.tr_status);

      let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_cpa_doc}`;
      setCpaName(url2);
      setCpa(record.tr_cpa_doc);

      let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
      setERName(url3);
      setER(record.tr_er_doc);

      let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
      setPDDName(url4);
      setPdd(record.tr_pdd_doc);


      let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_irr_doc}`;
      setRRName(url6);
      setRR(record.tr_irr_doc);
    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };



  const handleRemarksChange = (content) => {
    setRemarkCTR(content);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);

    if (remarkCTR == null || remarkCTR == '<p><br></p>' || remarkCTR == '') {
      toast.error('Please Add Some Remarks')
    }
    else {
      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("tr_cpa_doc", file2);
        formDataToSend.append("tr_er_doc", file3);
        formDataToSend.append("tr_pdd_doc", file4);
        formDataToSend.append("tr_irr_doc", file6);
        formDataToSend.append("tr_client_remarks", remarkCTR);

        const response = await axios.post(
          `${client_tr_round_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (

          fileCpaSizeError ||
          filePDDSizeError ||
          fileRRSizeError ||
          fileERSizeError
        ) {
          toast.error(`File size must below ${dynamicMaxSize} MB`);
        } else {
          toast.success("Document Submitted Successfully");

          setFile2(null);
          setFile3(null);
          setFile4(null);
          setFile6(null);
          setRemark("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        // setLoading(false);
        setIsSubmitting(false);

      }
    }
  };

  return (
    <>
      {trStatus == 4 ?
        <div>
          <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Upload Document
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Uploaded Document
                  </th>

                </tr>
              </thead>
              <tbody>



                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    PDD / MR
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  {/* <td className="p-3">
                 
                    <FileUploader
                      handleChange={handleFilePDDChange}
                      name="pdd_report"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handlePDDSizeError}
                    />
                 

                  {filePDDSizeError ? (
                    <span className="text-danger">
                      File size greater than {dynamicMaxSize} MB is not allowed
                    </span>
                  ) : (
                    <span>{file4 ? file4.name : ""}</span>
                  )}
                </td> */}

                  <FileUploaderComponent file={file4} setFile={setFile4} />


                  <td>
                    <a href={pddName} target="_blank">
                      {pdd}
                    </a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    ER<span style={{ color: "red" }}>*</span>
                  </td>

                  {/* <td className="p-3">
                  
                    <FileUploader
                      handleChange={handleFileERChange}
                      name="er_document"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleERSizeError}
                    />
                

                  {fileERSizeError ? (
                    <span className="text-danger">
                      File size greater than {dynamicMaxSize} MB is not allowed
                    </span>
                  ) : (
                    <span>{file3 ? file3.name : ""}</span>
                  )}
                </td> */}

                  <FileUploaderComponent file={file3} setFile={setFile3} />


                  <td>
                    <a href={erName}>{er}</a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">IRR</td>


                  {/* <td className="p-3">
                 
                    <FileUploader
                      handleChange={handleFileRRReportChange}
                      name="rr_report"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleRRSizeError}
                    />
             

                  {fileRRSizeError ? (
                    <span className="text-danger">
                      File size greater than {dynamicMaxSize} MB is not allowed
                    </span>
                  ) : (
                    <span>{file6 ? file6.name : ""}</span>
                  )}
                </td> */}

                  <FileUploaderComponent file={file6} setFile={setFile6} />


                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">CPA</td>


                  {/* <td className="p-3">
               
                    <FileUploader
                      handleChange={handleFileCpaChange}
                      name="cpa_report"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleCpaSizeError}
                    />
                 

                  {fileCpaSizeError ? (
                    <span className="text-danger">
                      File size greater than {dynamicMaxSize} MB is not allowed
                    </span>
                  ) : (
                    <span>{file2 ? file2.name : ""}</span>
                  )}
                </td> */}

                  <FileUploaderComponent file={file2} setFile={setFile2} />


                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>

                </tr>

              </tbody>
            </table>



{ remarkTL &&
           <div className="mb-4">
              <p className="lightgreen fw-bold ">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTL}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: remarkTL }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>}

            { remarkAT &&
           <div className="mb-4">
              <p className="lightgreen fw-bold ">Team Member Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: remarkAT }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>}

            <div style={{ marginTop: '60px' }}>
              <p className="lightgreen fw-bold">Client Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkCTR}
                  name="remarks"
                  onChange={handleRemarksChange}
                  dangerouslySetInnerHTML={{ __html: remark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>




            <div className="container mt-4 d-flex justify-content-end pr-0">
              <button
                className="btn px-3 mt-4 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
            {loading && <div className="loader"></div>}
          </form>
          </>

        </div>
        :

        <div>
          <table className="table table-bordered  table-hover">
            <thead>
              <tr>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  S.No
                </th>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Document Name
                </th>


                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Uploaded Document
                </th>

              </tr>
            </thead>
            <tbody>



              <tr>
                <td class="text-center">1</td>

                <td className="text-center lightgreen p-3">
                  PDD / MR
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={pddName} target="_blank">
                    {pdd}
                  </a>
                </td>

              </tr>

              <tr>
                <td class="text-center">2</td>

                <td className="text-center lightgreen p-3">
                  ER<span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={erName}>{er}</a>
                </td>

              </tr>

              <tr>
                <td class="text-center">3</td>

                <td className="text-center lightgreen p-3">IRR</td>

                <td>
                  <a href={rrName}>{rr}</a>
                </td>

              </tr>

              <tr>
                <td class="text-center">4</td>

                <td className="text-center lightgreen p-3">CPA</td>

                <td>
                  <a href={cpaName}>{cpa}</a>
                </td>

              </tr>

            </tbody>
          </table>


          {/* {(trStatus == 7 && remarkCTR == null)  || (trStatus == 2 && remarkCTR == null) || (trStatus == 3 && remarkCTR == null) ? '' : */}
          {remarkTL &&
            <div className="mb-4">
              <p className="lightgreen fw-bold ">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTL}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkTL }}
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          }

{ remarkAT &&
           <div className="mb-4">
              <p className="lightgreen fw-bold ">Team Member Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: remarkAT }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>}


          {/* {(trStatus == 7 && remarkCTR == null)  || (trStatus == 2 && remarkCTR == null) || (trStatus == 3 && remarkCTR == null) ? '' : */}
          {remarkCTR &&
            <div style={{ marginTop: '60px' }}>
              <p className="lightgreen fw-bold">Client Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkCTR}
                  name="remarks"
                  readOnly
                  dangerouslySetInnerHTML={{ __html: remark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          }



        </div>

      }
    </>
  )
}

export default ClientTechReview
