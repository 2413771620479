import { Space, Table, Tag } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_HEADER, proposal_summary_url } from "../config";
import {Spin} from  "antd";

const Summary = ({ proposalId }) => {
    const [summaryData, setSummaryData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSummary = async () => {
            try {
                setLoading(true);
                const response = await axios.post(
                    proposal_summary_url,
                    {
                        proposal_id: proposalId,
                    },
                    API_HEADER,
                );
                setSummaryData(response.data.record);
                setLoading(false);


            } catch (error) {
            }
        };

        fetchSummary();
    }, [proposalId]);

    return (
        <>
           <Spin spinning={loading}>
            <table className="table table-bordered my-4 table-hover">
                <thead>
                    <tr>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Action Date</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Action Taken By</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color" style={{width:'40%'}}>Remarks</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Status</th>
                    </tr>
                </thead>
                <tbody>

                {
                summaryData.length > 0 ? (

                    summaryData.map((data, index) => (
                        <tr key={index}>
                            <td>{data.created_at.slice(0, 10)} {data.created_at.slice(11, 19)}</td>
                            <td>{data.action_by}</td>
                            <td>{data.remarks && data.remarks.replace(/<[^>]+>/g, '')}</td>
                            <td>
                                    <p>{data.status}</p>
                            
                            </td>
                        </tr>
                    ))

                ) : (
                    <tr>
                        <td colSpan="5" className="text-center">No Data</td>
                    </tr>
                )}

                </tbody>
            </table>
            </Spin>
        </>
    )
};
export default Summary;

