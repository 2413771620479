import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_HEADER } from "../../config";
import {
  get_assesment_url,
  team_change_url,
  get_ta_tr_experts,
  get_sectoralscope_url,
  get_project_team_info,
  BASE_DOCUMENT,
  get_validator_verifier,
  get_gis_expert,
  get_local_experts
} from "../../config";
import { get_project_details_url } from "../../config";
import { get_trsbu_url } from "../../config";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { Alert } from "antd";

import { DeleteTwoTone } from "@ant-design/icons";
import FileUploaderComponent from "../../utlis/FileUploaderComponent";
import TeamChangeDisable from "./TeamChangeDisable";

const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const ATTeamChange = ({ teamId, isTL1 }) => {
  const { id } = useParams();

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const navigate = useNavigate();

  const userdata = JSON.parse(sessionStorage.getItem("user"));

  const [projectid, setProjectId] = useState(null);

  const [atlist, setAtList] = useState([]);

  const [trlist, setTRList] = useState([]);

  const [isTL, setIsTL] = useState(false);
  const [isAT, setIsAT] = useState(false);
  const [member, setMember] = useState();
  const [projectstatus, setProjectStatus] = useState(null);
  const [status, SetStatus] = useState(null);

  const [teamLeader, setTeamLeader] = useState("");
  const [taExpert, setTAExpert] = useState([]);
  const [validator, setValidator] = useState("");
  const [financeExpert, setFinanceExpert] = useState("");
  const [localExpert, setLocalExpert] = useState("");
  // const [methExpert, setMethExpert] = useState("");
  const [traineeExpert, setTraineeExpert] = useState("");
  const [technicalReviewer, setTechnicalReviewer] = useState("");
  const [expertTechnicalReviewer, setExpertTechnicalReviewer] = useState([]);
  const [teamChangeDoc, setTeamChangeDoc] = useState([]);
  const [tlRemarks, setTlRemarks] = useState("");
  const [atRemarks, setATRemarks] = useState("");
  const [sbuRemarks, setSbuRemarks] = useState("");

  const [fileteamChangeDocSizeError, SetFileteamChangeDocSizeError] =
    useState(false);
  const [teamDocName, setTeamDocName] = useState(null);
  const [teamDoc, setTeamDoc] = useState("");

  const [taTrExpert, setTATRExpert] = useState([]);
  const [mytaexpert, setMyTaexpert] = useState([]);
  const [myexperttr, setMyExpertTr] = useState([]);
  const [trExpert, setTRExpert] = useState([]);
  const [apiData, setAPIData] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [mysectorscope, setMySectorScope] = useState([]);
  const [localExpertData, setLocalExpertData] = useState([]);
  const [country,setCountry] = useState('')
    
  
  const [record, setRecord] = useState("");

  const [teamChangeStatus, setTeamChangeStatus] = useState("");
  const [teamChangeId, setteamChangeId] = useState("");
  const [tmsRemarks, setTmsRemarks] = useState("");
  const [tmRemarks, setTmRemarks] = useState("");

  const [newCoi, setNewCoi] = useState([]);
  const [fileCOISizeError, setFileCOISizeError] = useState(false);
  const [coi, setCoi] = useState([]);
  const [coiname, setCoiName] = useState([]);

  const [f24File, setF24File] = useState("");
  const [f21File, setF21File] = useState("");
  const [otherDocFile, setOtherDocFile] = useState([]);

  const [f24Name, setF24Name] = useState(null);
  const [f24, setF24] = useState("");
  const [f21Name, setF21Name] = useState(null);
  const [f21, setF21] = useState("");

  const [validatorVerifier, setvalidatorVerifier] = useState([]);
  const [myvalidatorverifier, setMyvalidatorVerifier] = useState([]);

  const [methExpert, setmethExpert] = useState([]);
  const [mymethexpert, setMymethExpert] = useState([]);

  const [traineeValidator, settraineeValidator] = useState([]);
  const [mytraineevalidator, setMytraineevalidator] = useState([]);

  const [gisExpert, setgisExpert] = useState([]);
  const [mygisexpert, setMygisExpert] = useState([]);

  const [formData, setFormData] = useState({
    team_leader: "",
    program: "",
    ta_expert: "",
    validator_verifier: [],
    finance_expert: "",
    local_expert: "",
    meth_expert: [],
    trainee_validator: [],
    technical_reviewer: "",
    expert_tr: "",
    sbu_head: "",
    pdd_documents: "",
    er_documents: "",
    mr_documents: "",
    evidence_name: [],
    gis_expert: [],
  });

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_team_info}/${ids}?team_id=${teamId}`,
        API_HEADER
      );
      setCountry(response.data?.record?.country)
      // setAPIData(response.data.record)
      // setFormData({
      //     program: response.data.record.program
      // })

      // const sectorscopes = response.data.record.sectoral_scope?.split(",").map(Number);
      // const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
      //     sectorscopes.includes(sectorscope.value)
      // );
      // setSelectedSector(filteredSectorScopes);

      const record1 = response.data.record.program;
      setTeamChangeStatus(response.data.record.team_change_status);

      if (response && response.data && response.data.teamsummary) {
        const record = response.data.teamsummary;

        setProjectId(record.project_id);
        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${record1}`
        );
        setRecord(record);

        setAPIData(record);
        setAtList(responseteamleader.data.data);

        setTeamLeader(record.team_leader);
        setTAExpert(record.ta_expert);
        // setValidator(record.validator_verifier);
        setFinanceExpert(record.finance_expert);
        setLocalExpert(record.local_expert);
        // setMethExpert(record.meth_expert);
        // setTraineeExpert(record.trainee_validator);
        setTechnicalReviewer(record.technical_reviewer);
        // setTeamDoc(record.team_change_document)
        setATRemarks(
          record.team_change_status == 1 ||
            record.team_change_status == 2 ||
            record.team_change_status == 5
            ? record.at_team_remarks == ""
            : record.team_change_status
        );
        setTlRemarks(record.tl_team_remarks);
        setTmRemarks(record.tm_team_remarks);
        setTmsRemarks(record.team_tms_remarks);

        setteamChangeId(record.id);
        setMember(record.team_change_status);

        setFormData({
          expert_tr: record.expert_tr,
          status: record.status,
          program: record.program,
          validator_verifier: record.validator_verifier,
          meth_expert: record.meth_expert,
          trainee_validator: record.trainee_validator,
          gis_expert: record.gis_expert,
        });

        // const isTeamLeader = record.team.some((item) => item.role_id == 1);

        // setIsTL(isTeamLeader);

        // const isTeamMember = record.team.some((item) => item.role_id > 1);
        // setIsAT(isTeamMember);

        setProjectStatus(record.project_status);
        SetStatus(record.status);

        // setF21File(response.data.teamsummary.f24_doc)
        // setF24File(response.data.teamsummary.f24_doc)

        let url2 = `${BASE_DOCUMENT}/projects/${record.project_id}/${record.f24_doc}`;

        setF24Name(url2);
        setF24(record.f24_doc);

        let url3 = `${BASE_DOCUMENT}/projects/${record.project_id}/${record.f21_doc}`;
        setF21Name(url3);
        setF21(record.f21_doc);

        // let url8 = `${BASE_DOCUMENT}/projects/${id}/${record.team_change_document}`;
        // setTeamDoc(url8);
        // setTeamDocName(record.team_change_document);

        const accumulatedFiles = record.coi_doc
          .split(",")
          .reduce((acc, item) => {
            const filename = `${BASE_DOCUMENT}/documents/${record.project_id}/${item}`;
            return {
              ...acc,
              [item]: { url: filename },
            };
          }, {});

        setCoiName(accumulatedFiles);
        setCoi(Object.keys(accumulatedFiles));
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  useEffect(() => {
    if (apiData) {
      //   const scopes = apiData.scope.split(",").map(Number);
      //   const filteredScopes = myscope.filter((scope) =>
      //     scopes.includes(scope.value)
      //   );
      //   setSelected(filteredScopes);

      const sectorscopes = apiData.sectoral_scope?.split(",").map(Number);
      const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
        sectorscopes?.includes(sectorscope.value)
      );
      setSelectedSector(filteredSectorScopes);
    }
  }, [apiData, mysectorscope]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  const fetchDataTeamChange = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_team_info}/${ids}?team_id=${teamId}`,
        API_HEADER
      );

      setTeamChangeStatus(response.data.record.team_change_status);
      setMember(response.data.record.team_change_status);
    } catch (error) {}
  };

  useEffect(() => {
    fetchDataTeamChange(id);
  }, [id]);

  useEffect(() => {
    const fetchDataTrData = async () => {
      try {
        const responsetrdata = await axios.get(`${get_trsbu_url}`);
        setTRList(responsetrdata.data.data);
      } catch (error) {}
    };

    fetchDataTrData();
  }, []);
  useEffect(() => {
    const fetchSectoralScope = async () => {
      try {
        const responsesectoralscope = await axios.get(
          `${get_sectoralscope_url}`
        );

        setMySectorScope(
          responsesectoralscope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.scope,
          }))
        );
      } catch (error) {}
    };

    fetchSectoralScope();
  }, []);

  useEffect(() => {
    const fetchDataTATRExpert = async () => {
      try {
        const responsetatrexpert = await axios.get(
          `${get_ta_tr_experts}?program_id=${
            formData.program
          } && sectorscope_id=${selectedSector.map(
            (value) => value.value
          )} && skill_id=7`
        );
        setTATRExpert(
          responsetatrexpert.data.data.map((taexpert) => ({
            value: taexpert.id,
            label: taexpert.name,
          }))
        );
      } catch (error) {}
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTATRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && taTrExpert.length > 0) {
      const taExpert = apiData.ta_expert?.split(",").map(Number);
      const filteredTaExpert = taTrExpert.filter((taexpert) =>
        taExpert?.includes(taexpert.value)
      );
      setMyTaexpert(filteredTaExpert);
    }
  }, [apiData, taTrExpert]);

  useEffect(() => {
    const fetchDataTRExpert = async () => {
      try {
        const responsetatrexperts = await axios.get(
          `${get_ta_tr_experts}?program_id=${
            formData.program
          } && sectorscope_id=${selectedSector.map(
            (value) => value.value
          )} && skill_id=8`
        );

        setTRExpert(
          responsetatrexperts.data.data.map((trexpert) => ({
            value: trexpert.id,
            label: trexpert.name,
          }))
        );
      } catch (error) {}
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && trExpert.length > 0) {
      const trExperts = apiData.expert_tr?.split(",").map(Number);
      const filteredTrExpert = trExpert.filter((taexpert) =>
        trExperts?.includes(taexpert.value)
      );

      setMyExpertTr(filteredTrExpert);
    }
  }, [apiData, trExpert]);

  useEffect(() => {
    const fetchDatavalidatorVerifier = async () => {
      try {
        const responsevalidatorverifier = await axios.get(
          `${get_validator_verifier}?program_id=${formData.program} && skill_id=2 &new_team=1`
        );
        setvalidatorVerifier(
          responsevalidatorverifier.data.data.map((validator_verifier) => ({
            value: validator_verifier.id,
            label: validator_verifier.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatavalidatorVerifier();
  }, [formData.program]);

  useEffect(() => {
    const fetchDatamethExpert = async () => {
      try {
        const responsemethexpert = await axios.get(
          `${get_validator_verifier}?program_id=${formData.program} && skill_id=5`
        );
        setmethExpert(
          responsemethexpert.data.data.map((methexpert) => ({
            value: methexpert.id,
            label: methexpert.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatamethExpert();
  }, [formData.program]);

  useEffect(() => {
    const fetchDatatraineeValidator = async () => {
      try {
        const responsetraineevalidator = await axios.get(
          `${get_validator_verifier}?program_id=${formData.program} && skill_id=3`
        );
        settraineeValidator(
          responsetraineevalidator.data.data.map((traineevalidator) => ({
            value: traineevalidator.id,
            label: traineevalidator.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatatraineeValidator();
  }, [formData.program]);

  useEffect(() => {
    if (apiData && methExpert.length > 0) {
      const meth = apiData?.meth_expert?.split(",").map(Number);

      const filteredMethExpert = methExpert.filter((expert) =>
        meth?.includes(expert.value)
      );

      setMymethExpert(filteredMethExpert);
    }
  }, [apiData, methExpert]);

  useEffect(() => {
    if (apiData && validatorVerifier.length > 0) {
      const validator = apiData?.validator_verifier?.split(",").map(Number);

      const filteredValidatorExpert = validatorVerifier.filter((expert) =>
        validator?.includes(expert.value)
      );

      setMyvalidatorVerifier(filteredValidatorExpert);
    }
  }, [apiData, validatorVerifier]);

  useEffect(() => {
    if (apiData && traineeValidator.length > 0) {
      const traineevalidator = apiData?.trainee_validator
        ?.split(",")
        .map(Number);

      const filteredTraineeValidator = traineeValidator.filter((expert) =>
        traineevalidator?.includes(expert.value)
      );

      setMytraineevalidator(filteredTraineeValidator);
    }
  }, [apiData, traineeValidator]);

  // useEffect(() => {
  //   const fetchDatagisExpert = async () => {
  //     try {
  //       const responsegisexpert = await axios.get(
  //         `${get_validator_verifier}?skill_id=9`
  //       );
  //       setgisExpert(
  //         responsegisexpert.data.data.map((gisexpert) => ({
  //           value: gisexpert.id,
  //           label: gisexpert.name,
  //         }))
  //       );
  //     } catch (error) {}
  //   };

  //   fetchDatagisExpert();
  // }, [apiData]);

  // useEffect(() => {
  //   if (apiData && gisExpert.length > 0) {
  //     const gisExperts = apiData?.gis_expert?.split(",").map(Number);

  //     const filteredGISExpert = gisExpert.filter((expert) =>
  //       gisExperts?.includes(expert.value)
  //     );

  //     setMygisExpert(filteredGISExpert);
  //   }
  // }, [apiData, gisExpert]);

  useEffect(() => {
    const fetchDatagisExpert = async () => {
      try {
        const responsegisexpert = await axios.get(
          `${get_gis_expert}?skill_id=9`
        );
        setgisExpert(
          responsegisexpert.data.data.map((gisexpert) => ({
            value: gisexpert.id,
            label: gisexpert.name,
          }))
        );
      } catch (error) {}
    };

    fetchDatagisExpert();
  }, [apiData]);

  useEffect(() => {
    if (apiData && gisExpert.length > 0) {
      const gisExperts = apiData?.gis_expert?.split(",").map(Number);

      const filteredGISExpert = gisExpert.filter((expert) =>
        gisExperts?.includes(expert.value)
      );

      setMygisExpert(filteredGISExpert);
    }
  }, [apiData, gisExpert]);


  useEffect(() => {
    const fetchLocalExpert = async () => {
      try {
        const responselocalexpert = await axios.get(
          `${get_local_experts}?country_id=${country}&&program_id=${formData.program}`
        );

        setLocalExpertData(responselocalexpert.data);
      } catch (error) {}
    };

    fetchLocalExpert();
  }, [country,formData.program,formData]);


  const handleEditAssesmentTeamSubmit = async (e) => {
    e.preventDefault();

    const TAExpertData = mytaexpert.map((value) => value.value);
    const TRExpertData = myexperttr.map((value) => value.value);

    const ValidatorVerifierData = myvalidatorverifier.map(
      (value) => value.value
    );
    const MathExpertData = mymethexpert.map((value) => value.value);
    const TraineeValidatorData = mytraineevalidator.map((value) => value.value);
    const gisexpert = mygisexpert.map((value) => value.value);

    // setIsModalOpen(false);
    if (atRemarks == null || atRemarks == "<p><br></p>" || atRemarks == "") {
      toast.error("Please Add Some Remarks");
    } else {
      try {
        // const formData = new FormData();
        // formData.append("project_id", projectid);
        // formData.append("team_leader", teamLeader);
        // formData.append("validator_verifier", validator);
        // formData.append("ta_expert", TAExpertData);
        // formData.append("meth_expert", methExpert);
        // formData.append("local_expert", localExpert);
        // formData.append("finance_expert", financeExpert);
        // formData.append("trainee_validator", traineeExpert);
        // formData.append("technical_reviewer", technicalReviewer);
        // formData.append("expert_tr", TRExpertData);
        // formData.append("team_change_status", member);
        // formData.append("tl_team_remarks", tlRemarks);
        // formData.append("team_change_id", teamChangeId);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", projectid);
        formDataToSend.append("team_leader", teamLeader);
        // formDataToSend.append("validator_verifier", validator);
        formDataToSend.append("validator_verifier", ValidatorVerifierData);
        formDataToSend.append("ta_expert", TAExpertData);
        // formDataToSend.append("meth_expert", methExpert);
        formDataToSend.append("meth_expert", MathExpertData);
        formDataToSend.append("local_expert", localExpert);
        formDataToSend.append("finance_expert", financeExpert);
        // formDataToSend.append("trainee_validator", traineeExpert);
        formDataToSend.append("trainee_validator", TraineeValidatorData);
        formDataToSend.append("technical_reviewer", technicalReviewer);
        formDataToSend.append("expert_tr", TRExpertData);
        formDataToSend.append("team_change_status", member);
        formDataToSend.append("at_team_remarks", atRemarks);
        formDataToSend.append("team_change_id", teamChangeId);
        formDataToSend.append("gis_expert", gisexpert);

        formDataToSend.append("f24_doc", f24File);
        formDataToSend.append("f21_doc", f21File);
        newCoi.map((fileList) => formDataToSend.append("coi_doc[]", fileList));

        Swal.fire({
          title: "Confirmation?",
          text: "Are you sure, you want to change Assessment Team?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const response = await axios.post(
              `${team_change_url}`,
              formDataToSend,
              CONFIG_Token2
            );

            if (response.status === 200 && response.data.status == true) {
              toast.success("Team Change Request Sent Successfully");
              // window.location.reload();
              navigate(`/dashboard`);
            } else {
              fetchData();
            }
          } else {
          }
        });
      } catch (error) {}
    }
  };

  const handleTlRemarksChange = (content) => {
    setTlRemarks(content);
  };

  const handleATRemarksChange = (content) => {
    setATRemarks(content);
  };

  const handleFileCOIChange = (file) => {
    setNewCoi((prev) => [...prev, ...Array.from(file)]);
    if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
      setFileCOISizeError(false);
    }

    setFileCOISizeError(false);

    setCoi((prevFiles) => [...prevFiles, ...Array.from(file)]);
  };

  const handleCOISizeError = (file) => {
    setFileCOISizeError(true);
  };

  const handleCOIDelete = (newCoi) => {
    setNewCoi((prevFiles) => prevFiles.filter((file) => file !== newCoi));
  };
  return (
    <div>
      {!isTL &&
      (teamChangeStatus == 2 ||
        teamChangeStatus == 5 ||
        teamChangeStatus == 1) ? (
        <form method="post" onSubmit={handleEditAssesmentTeamSubmit}>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="teamleader"
                className="form-label lightgreen fs-6"
              >
                Team Leader
                <span style={{ color: "red" }}>*</span>
              </label>

              <select
                id="team_leader"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="team_leader"
                value={teamLeader}
                onChange={(e) => setTeamLeader(e.target.value)}
              >
                <option selected value={""}>
                  Select
                </option>
                {atlist
                  .filter((option) => option.skill_id === 1)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label htmlFor="taexpert" className="form-label lightgreen fs-6">
                TA Expert
                <span style={{ color: "red" }}>*</span>
              </label>

              <MultiSelect
                options={taTrExpert}
                value={mytaexpert}
                onChange={setMyTaexpert}
                labelledBy="Select"
              />
            </div>

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="teamleader"
                className="form-label lightgreen fs-6"
              >
                Local Expert
                <span style={{ color: "red" }}>*</span>
              </label>

              <select
                id="local_expert"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="local_expert"
                value={localExpert}
                // readOnly={true}
                onChange={(e) => setLocalExpert(e.target.value)}
              >
                <option value={""}>To be added later</option>

                {localExpertData
                  // .filter((option) => option.skill_id === 6)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="financeexpert"
                className="form-label lightgreen fs-6"
              >
                Finance Expert
              </label>

              <select
                id="finance_expert"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="finance_expert"
                value={financeExpert}
                // readOnly={true}
                onChange={(e) => setFinanceExpert(e.target.value)}
              >
                <option selected value={""}>
                  Select
                </option>
                {atlist
                  .filter((option) => option.skill_id === 4)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>

            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
        <label
          htmlFor="validator"
          className="form-label lightgreen fs-6"
        >
          Validator/verifier
        </label>

        <select
          id="validator_verifier"
          className="form-select borderlightgreen form-select-sm"
          aria-label="Default select example"
          name="validator_verifier"
          value={validator}
          // readOnly={true}
          onChange={(e) => setValidator(e.target.value)}
        >
          <option selected value={""}>
            Select
          </option>
          {atlist
            .filter((option) => option.skill_id === 2)
            .map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </select>
      </div> */}

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label htmlFor="validator" className="form-label lightgreen fs-6">
                Validator/verifier
              </label>

              <MultiSelect
                options={validatorVerifier}
                value={myvalidatorverifier}
                onChange={setMyvalidatorVerifier}
                labelledBy="Select"
                // className={formEdit ? 'multi' : ''}
              />
            </div>

            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
        <label
          htmlFor="methexpert"
          className="form-label lightgreen fs-6"
        >
          Meth Expert
        </label>

        <select
          id="meth_expert"
          className="form-select borderlightgreen form-select-sm"
          aria-label="Default select example"
          name="meth_expert"
          value={methExpert}
          // readOnly={true}
          onChange={(e) => setMethExpert(e.target.value)}
        >
          <option selected value={""}>
            Select
          </option>
          {atlist
            .filter((option) => option.skill_id === 5)
            .map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </select>
      </div> */}

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="methexpert"
                className="form-label lightgreen fs-6"
              >
                Meth Expert
              </label>

              <MultiSelect
                options={methExpert}
                value={mymethexpert}
                onChange={setMymethExpert}
                labelledBy="Select"
                // className={formEdit ? 'multi' : ''}
              />
            </div>
          </div>

          <div className="row">

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label htmlFor="taexpert" className="form-label lightgreen fs-6">
                Trainee Validator/verifier
              </label>

              <MultiSelect
                options={traineeValidator}
                value={mytraineevalidator}
                onChange={setMytraineevalidator}
                labelledBy="Select"
                // className={formEdit ? 'multi' : ''}
              />
            </div>

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="technicalreviewer"
                className="form-label lightgreen fs-6"
              >
                Technical Reviewer
                <span style={{ color: "red" }}>*</span>
              </label>

              <select
                id="technical_reviewer"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="technical_reviewer"
                required
                value={technicalReviewer}
                onChange={(e) => setTechnicalReviewer(e.target.value)}
              >
                <option selected value={""}>
                  Select
                </option>
                {trlist
                  .filter((option) => option.designation_id == 7)
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="technicalreviewer"
                className="form-label lightgreen fs-6"
              >
                Expert to Technical Reviewer
                <span style={{ color: "red" }}>*</span>
              </label>

              <MultiSelect
                options={trExpert}
                value={myexperttr}
                onChange={setMyExpertTr}
                labelledBy="Select"
              />
            </div>

            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label
                htmlFor="gis_expert"
                className="form-label lightgreen fs-6"
              >
                GIS Expert
              </label>

              <MultiSelect
                options={gisExpert}
                value={mygisexpert}
                onChange={setMygisExpert}
                labelledBy="Select"
                // className={formEdit ? 'multi' : ''}
              />
            </div>
          </div>

          <div>
            <p className="textlightgreen fw-bold mb-3 fs-5">Documents</p>
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Upload Document
                  </th>
                  {teamChangeStatus >= 2 ? (
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Uploaded Document
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    COI Documents
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </td>

                  <td>
                    <FileUploader
                      handleChange={handleFileCOIChange}
                      name="coi_doc"
                      types={fileTypes}
                      multiple="true"
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleCOISizeError}
                    />

                    {fileCOISizeError ? (
                      <span className="text-danger">
                        File size greater than {dynamicMaxSize} mb is not
                        allowed
                      </span>
                    ) : (
                      <span>
                        {newCoi.map((newCoi) => (
                          <div>
                            <span style={{ marginRight: "20px" }}>
                              &nbsp;
                              {
                                <DeleteTwoTone
                                  twoToneColor="#eb2f96"
                                  onClick={() => handleCOIDelete(newCoi)}
                                />
                              }
                            </span>
                            <span>{`File name: ${newCoi?.name}`}</span>
                          </div>
                        ))}
                      </span>
                    )}
                  </td>

                  <td>
                    {Object.keys(coiname).map((fileName) => (
                      <div key={fileName}>
                        <a target="_blank" href={coiname[fileName].url}>
                          {fileName}
                        </a>
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    F24 Document
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </td>


                  <FileUploaderComponent file={f24File} setFile={setF24File} />

                  <td>
                    <a href={f24Name} target="_blank">
                      {f24}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    F21 Document
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </td>

                  <FileUploaderComponent file={f21File} setFile={setF21File} />

                  <td>
                    <a href={f21Name} target="_blank">
                      {f21}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {teamChangeStatus == 1 ||
          teamChangeStatus == 2 ||
          teamChangeStatus == 5 ? (
            <>
              <div className="row">
                <div className="col-6 my-4 ">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value="">Select</option>

                    <option value={2}>Send to Team Members</option>
                    <option value={3}>Forward to TL</option>
                    <option value={4}>Send to TM Support</option>
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    Team Member Remarks<span style={{ color: "red" }}>*</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={atRemarks}
                    name="remarks"
                    onChange={handleATRemarksChange}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              {tlRemarks && (
                <div className="row mt-4">
                  <div>
                    <label className="form-label lightgreen fs-6 mb-3">
                      Team Leader Remarks<span style={{ color: "red" }}>*</span>
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={tlRemarks}
                      name="remarks"
                      readOnly={true}
                      //   onChange={handleATRemarksChange}
                      style={{
                        // width: "68vw",
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}

              {teamChangeStatus == 5 || teamChangeStatus == 6 ? (
                <div className="row my-4">
                  <div>
                    <label className="form-label lightgreen fs-6 mb-3">
                      TM Support Remark
                    </label>
                    <hr className="lightgreen" />
                    {teamChangeStatus == 6 || teamChangeStatus == 5 ? (
                      <div className="row  my-4">
                        <span>
                          {teamChangeStatus == 6 ? (
                            <Alert
                              className="col-12"
                              message="Approved"
                              type="success"
                              showIcon
                            />
                          ) : teamChangeStatus == 5 ? (
                            <Alert
                              className="col-12"
                              message="Clarification Required"
                              banner
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <ReactQuill
                      theme="snow"
                      value={tmsRemarks}
                      name="remarks"
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-12 d-flex justify-content-end gap-3 pr-0">
                <button
                  className="btn  my-4 fs-5"
                  style={{
                    backgroundColor: "#07b6af",
                    color: "white",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-6 my-4 ">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    disabled
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value="">Select</option>

                    <option value={2}>Send to Team Members</option>
                    <option value={3}>Forward to TL</option>
                    <option value={4}>Send to TM Support</option>
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    Team Member Remarks<span style={{ color: "red" }}>*</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={atRemarks}
                    name="remarks"
                    readOnly={true}
                    //   onChange={handleATRemarksChange}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              {tlRemarks && (
                <div className="row mt-4">
                  <div>
                    <label className="form-label lightgreen fs-6 mb-3">
                      Team Leader Remarks<span style={{ color: "red" }}>*</span>
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={tlRemarks}
                      name="remarks"
                      readOnly={true}
                      //   onChange={handleATRemarksChange}
                      style={{
                        // width: "68vw",
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}

              {teamChangeStatus == 5 || teamChangeStatus == 6 ? (
                <div className="row my-4">
                  <div>
                    <label className="form-label lightgreen fs-6 mb-3">
                      TM Support Remark
                    </label>
                    <hr className="lightgreen" />
                    {teamChangeStatus == 6 || teamChangeStatus == 5 ? (
                      <div className="row  my-4">
                        <span>
                          {teamChangeStatus == 6 ? (
                            <Alert
                              className="col-12"
                              message="Approved"
                              type="success"
                              showIcon
                            />
                          ) : teamChangeStatus == 5 ? (
                            <Alert
                              className="col-12"
                              message="Clarification Required"
                              banner
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <ReactQuill
                      theme="snow"
                      value={tmsRemarks}
                      name="remarks"
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {teamChangeStatus == 7 || teamChangeStatus == 8 ? (
                <div className="row my-4">
                  <div>
                    <label className="form-label lightgreen fs-6 mb-3">
                      TM Remark
                    </label>
                    <hr className="lightgreen" />
                    {teamChangeStatus == 7 || teamChangeStatus == 8 ? (
                      <div className="row  my-4">
                        <span>
                          {teamChangeStatus == 8 ? (
                            <Alert
                              className="col-12"
                              message="Approved"
                              type="success"
                              showIcon
                            />
                          ) : teamChangeStatus == 7 ? (
                            <Alert
                              className="col-12"
                              message="Clarification Required"
                              banner
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    <ReactQuill
                      theme="snow"
                      value={tmRemarks}
                      name="remarks"
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </form>
      ) : (
        <TeamChangeDisable teamId={teamId} />
      )}
    </div>
  );
};

export default ATTeamChange;
