import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  BASE_DOCUMENT,
  get_KickoutInfo_url,
  tl_ccreview_kickout_url,
} from "../../../config";

import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER, get_list_of_users_sbu_cc_url } from "../../../config";
import FileUploaderComponent from "../../../utlis/FileUploaderComponent";
import LoaderComponent from "../../../utlis/LoderComponent";

const ATKickoutCCRound = (props) => {
  const { id } = useParams();
  const { isTL } = props;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;
  const [member, setMember] = useState();

  const [ccTLReviewRemark, setCCTLReviewRemark] = useState("");
  const [ccATReviewRemark, setCCATReviewRemark] = useState("");
  const [ccReviewRemark, setCCReviewRemark] = useState("");
  const [ccTRReviewRemark, setCCTRReviewRemark] = useState("");
  const [ccReviewClientRemark, setCCReviewClientRemark] = useState("");
  const [tlCCReviewStatus, setTLCCReviewStatus] = useState("");

  const [kickoutStatus, setkickoutStatus] = useState("");
  const [kickoutId, setKickoutId] = useState("");

  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const [file6, setFile6] = useState("");
  const [file7, setFile7] = useState("");

  const [fvrName, setFVRName] = useState(null);
  const [fvr, setFvr] = useState("");
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null);
  const [auditFinding, setAuditFinding] = useState("");
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");

  const [listOfUsers, setListOfUsers] = useState([]);
  const [listItem, setListItem] = useState("");
  const [changeType, setChangeType] = useState("");
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateError, setDateError] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

    const [list, setList] = useState('')
      const [CCName, setCCName] = useState('')
    
  

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );

      const { record } = response.data;
      const { kickout } = response.data;

      setKickoutId(kickout.id);
      setkickoutStatus(record.kickout_status);

      setTLCCReviewStatus(kickout.cc_status);
      setCCTLReviewRemark(kickout.cc_tl_remarks);
      // setCCATReviewRemark(kickout.cc_at_remarks)
      setCCATReviewRemark(
        kickout.kickout_status == 15 ||
          kickout.kickout_status == 17 ||
          kickout.kickout_status == 19 ||
          kickout.kickout_status == 21 ||
          kickout.kickout_status == 22
          ? (kickout.cc_at_remarks = "")
          : kickout.cc_at_remarks
      );
      setCCReviewRemark(kickout.cc_ccremarks);
      setCCReviewClientRemark(kickout.cc_client_remarks);
      setCCTRReviewRemark(kickout.cc_tr_remarks);
      // setMember(kickout.cc_status)
      setMember(kickout.kickout_status);

      setChangeType(kickout.cc_changes);
      setListItem(kickout.cc_id);
      setStartDate(kickout.cc_starting_date);
      setEndDate(kickout.cc_end_date);

      let url1 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_fvr_report}`;
      setFVRName(url1);
      setFvr(kickout.cc_fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_err_document}`;
      setERName(url2);
      setER(kickout.cc_err_document);

      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_pdd_document}`;
      setPDDName(url3);
      setPdd(kickout.cc_pdd_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_cpa_document}`;
      setCpaName(url4);
      setCpa(kickout.cc_cpa_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_irr_document}`;
      setRRName(url5);
      setRR(kickout.cc_irr_document);

      let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_audit_finding}`;
      setAuditFindingName(url6);
      setAuditFinding(kickout.cc_audit_finding);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_other_docs}`;
      setOtherName(url7);
      setOther(kickout.cc_other_docs);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${get_list_of_users_sbu_cc_url}`);
      const { data } = response.data;

      setListOfUsers(data);
    };
    fetchData();
  }, []);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercent(percentCompleted);
    },
  };

  const handleRemarksChange = (content) => {
    setCCTLReviewRemark(content);
  };
  const handleATRemarksChange = (content) => {
    setCCATReviewRemark(content);
  };

  const navigate = useNavigate();

  const handleSubmitAgain = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);

    if (
      ccATReviewRemark == null ||
      ccATReviewRemark == "<p><br></p>" ||
      ccATReviewRemark == ""
    ) {
      toast.error("Please Add Some Remarks");
    } else {
      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("cc_fvr_report", file1);
        formDataToSend.append("cc_cpa_document", file5);
        formDataToSend.append("cc_err_document", file2);
        formDataToSend.append("cc_pdd_document", file3);
        formDataToSend.append("cc_audit_finding", file6);
        formDataToSend.append("cc_irr_document", file4);
        formDataToSend.append("cc_other_docs[]", file7);
        formDataToSend.append("kickout_status", member);
        formDataToSend.append("cc_at_remarks", ccATReviewRemark);
        formDataToSend.append("cc_changes", changeType); // Add change type
        formDataToSend.append("kickout_id", kickoutId);

        const response = await axios.post(
          `${tl_ccreview_kickout_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success(" Submitted Successfully");
          setCCTLReviewRemark("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        // setLoading(false);
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${get_list_of_users_sbu_cc_url}`)
      const { data } = response.data
      setListOfUsers(data)
      data.map((item, i) => {
        if (item.id == list) {
          setCCName(item.name)
        }
      })
    }
    fetchData()
  }, [list])

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);

    if (ccATReviewRemark == null || ccATReviewRemark == '<p><br></p>' || ccATReviewRemark == '') {
      toast.error('Please Add Some Remarks')
    }
    else {
      try {

        // setLoading(true);
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("cc_at_remarks", ccATReviewRemark);
        formDataToSend.append("kickout_status", 14);
        formDataToSend.append('cc_starting_date', startDate)
        formDataToSend.append('cc_completion_date', endDate)
        formDataToSend.append('cc_id', list)
        formDataToSend.append('kickout_id', kickoutId)


        const response = await axios.post(
          `${tl_ccreview_kickout_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success(" Submitted Successfully");
          setCCTLReviewRemark("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        // setLoading(false);
        setIsSubmitting(false);

      }
    }
  };

  
  const handleListOfUsers = (event) => {
    const selectedList = event.target.value;
    setList(selectedList)
  }

  const handleStartDateChange = (e) => {
    const value = e.target.value;
    setStartDate(value);
  }
  const handleEndDateChange = (e) => {
    const value = e.target.value;
    setEndDate(value);
  }

  const getCurrentDate = () => {
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    const year = today.getFullYear();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }

    return `${year}-${month}-${day}`;
  };

  return (
    <>
           {(kickoutStatus == 13 || kickoutStatus == 14) ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >

            <div className="col-12 border-0 mx-auto">
              <table className="table table-bordered  table-hover">

                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Uploaded Document
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      FVR Report
                      <span style={{ color: "red" }}>*</span>
                    </td>

                    <td>
                      <a href={fvrName} target="_blank">{fvr}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">
                      ER
                      <span style={{ color: "red" }}>*</span>
                    </td>

                    <td>
                      <a href={erName} target="_blank">{er}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      PDD/MR
                      <span style={{ color: "red" }}>*</span>
                    </td>

                    <td>
                      <a href={pddName} target="_blank">{pdd}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">4</td>

                    <td className="text-center lightgreen p-3">
                      IRR
                    </td>

                    <td>
                      <a href={rrName}>{rr}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">5</td>

                    <td className="text-center lightgreen p-3">
                      CPA
                    </td>

                    <td>
                      <a href={cpaName}>{cpa}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">6</td>

                    <td className="text-center lightgreen p-3">
                      Audit Finding
                      <span style={{ color: "red" }}>*</span>
                    </td>

                    <td>
                      <a href={auditFindingName} target="_blank">{auditFinding}</a>
                    </td>
                  </tr>


                  <tr>
                    <td class="text-center">7</td>


                    <td className="text-center lightgreen p-3">
                      Other Document
                    </td>

                    <td>
                      <a target="_blank" href={otherName}>
                        {other}
                      </a>
                    </td>


                  </tr>

                </tbody>


              </table>
              {kickoutStatus === 13 ? (
                <div>
                  <div className='row'>
                    <div className="col-lg-6 col-md-4 col-12 mb-3 d-flex mt-4 ">
                      <label htmlFor="list_of_users" className="form-label lightgreen fs-6" style={{ width: '200px' }}>
                        CC Reviewer<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="list_of_users"
                        className="form-select borderlightgreen form-select-sm"
                        aria-label="Default select example"
                        name="list_of_users"
                        onChange={handleListOfUsers}
                        required
                      >
                        <option value={""}>Select</option>
                        {listOfUsers.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-2 mt-4">
                    <div className="col-6 mb-3">
                      <label htmlFor="startdate" className="form-label lightgreen fs-6">
                        Start Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className={`form-control borderlightgreen`}
                        id="start_date"
                        required
                        name="start_date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        min={getCurrentDate()}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <label htmlFor="enddate" className="form-label lightgreen fs-6">
                        Likely Completion Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className={`form-control borderlightgreen`}
                        id="end_date"
                        required
                        name="end_date"
                        value={endDate}
                        onChange={startDate ? handleEndDateChange : undefined} // Only allow changing when startDate is set
                        min={startDate ? startDate : undefined} // Set min to startDate if startDate is set
                        disabled={!startDate} // Disable input until startDate is selected
                      />
                      {/* {dateError && <div className="text-danger">{dateError}</div>} */}
                    </div>
                  </div>
                  <div>
                    <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks<span style={{ color: "red" }}>*</span></p>
                    <hr className="mb-3 lightgreen" />
                    <ReactQuill
                      theme="snow"
                      value={ccATReviewRemark}
                      name="cctlremark"
                      dangerouslySetInnerHTML={{ __html: ccATReviewRemark }}
                      onChange={handleATRemarksChange}
                      style={{ height: "20vh", marginBottom: "2rem" }}
                      className="mb-5"
                    />
                  </div>

                  <div className="container mt-4 d-flex justify-content-end pr-0">
                    <button
                      className="btn px-3 fs-5"
                      style={{
                        backgroundColor: "#07b6af",
                        color: "white",
                      }}
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className='row'>
                    <div className="col-lg-6 col-md-4 col-12 mb-3 d-flex mt-4 ">
                      <label htmlFor="list_of_users" className="form-label lightgreen fs-6" style={{ width: '200px' }}>
                        CC Reviewer<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="list_of_users"
                        className="form-select borderlightgreen form-select-sm"
                        aria-label="Default select example"
                        name="list_of_users"
                        readOnly={true}
                        disabled
                        value={listItem}
                      >
                        <option value={""}>Select</option>
                        {listOfUsers.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-2 mt-4">
                    <div className="col-6 mb-3">
                      <label htmlFor="startdate" className="form-label lightgreen fs-6">
                        Start Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className={`form-control borderlightgreen`}
                        id="start_date"
                        required
                        name="start_date"
                        value={startDate}
                        readOnly={true}
                        min={getCurrentDate()}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <label htmlFor="enddate" className="form-label lightgreen fs-6">
                        Likely Completion Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className={`form-control borderlightgreen`}
                        id="end_date"
                        required
                        name="end_date"
                        value={endDate}
                        readOnly={true}
                        min={startDate}
                      />
                      {dateError && <div className="text-danger">{dateError}</div>}
                    </div>
                  </div>
                  <div>
                    <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks<span style={{ color: "red" }}>*</span></p>
                    <hr className="mb-3 lightgreen" />
                    <ReactQuill
                      theme="snow"
                      value={ccATReviewRemark}
                      name="cctlremark"
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: ccATReviewRemark }}
                      style={{ height: "20vh", marginBottom: "2rem" }}
                      className="mb-5"
                    />
                  </div>

                  {
                    ccTLReviewRemark &&

                    <div>
                      <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks<span style={{ color: "red" }}>*</span></p>
                      <hr className="mb-3 lightgreen" />
                      <ReactQuill
                        theme="snow"
                        value={ccTLReviewRemark}
                        name="cctlremark"
                        readOnly={true}
                        dangerouslySetInnerHTML={{ __html: ccTLReviewRemark }}
                        style={{ height: "20vh", marginBottom: "2rem" }}
                        className="mb-5"
                      />
                    </div>

                  }


                </div>
              )}

            </div> 

            {loading && <div className="loader"></div>}
          </form>
        </>

      )

        :
      kickoutStatus === 15 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmitAgain}
            encType="multipart/form-data"
          >
            <div className="cc-review1">
              <div className="cc-review2">
                <span className="cc-review3">CC Reviewed By </span>
                <select
                  id="list_of_users"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="list_of_users"
                  value={listItem}
                  readOnly={true}
                  disabled
                  style={{ flex: "1" }}
                >
                  <option value={""}>Select</option>
                  {listOfUsers.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Document Name
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Upload Document
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Uploaded Document
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    FVR Report
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file1} setFile={setFile1} />

                  <td>
                    <a href={fvrName} target="_blank">
                      {fvr}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    ER
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file2} setFile={setFile2} />

                  <td>
                    <a href={erName} target="_blank">
                      {er}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    PDD/MR
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file3} setFile={setFile3} />

                  <td>
                    <a href={pddName} target="_blank">
                      {pdd}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">IRR</td>

                  <FileUploaderComponent file={file4} setFile={setFile4} />

                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">5</td>

                  <td className="text-center lightgreen p-3">CPA</td>

                  <FileUploaderComponent file={file5} setFile={setFile5} />

                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">6</td>

                  <td className="text-center lightgreen p-3">
                    Audit Finding
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file6} setFile={setFile6} />

                  <td>
                    <a href={auditFindingName} target="_blank">
                      {auditFinding}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">7</td>

                  <td className="text-center lightgreen p-3">Other Document</td>

                  <FileUploaderComponent file={file7} setFile={setFile7} />

                  <td>
                    <a target="_blank" href={otherName}>
                      {other}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row">
              <div className="col-4 my-4">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Choose Change Type
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="changetype"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="change"
                  value={changeType}
                  required
                  onChange={(e) => {
                    setChangeType(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value={1}>Minor Changes</option>
                  <option value={2}>Major Changes</option>
                </select>
              </div>

              {changeType == null ? (
                <div className="col-4 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value="">Select</option>

                    <option value={16}>Forward to TL</option>
                    <option value={18}>Reply to Client</option>
                    <option value={20}>Send to TR</option>
                    <option value={23}>Send to CC</option>
                    
                  </select>
                </div>
              ) : changeType == 1 ? (
                <div className="col-4 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {/* <option value={7}>Send to CC</option>
                          <option value={4}>Reply to Client</option> */}
                    <option value={16}>Forward to TL</option>
                    <option value={18}>Reply to Client</option>
                    <option value={23}>Send to CC</option>
                  </select>
                </div>
              ) : changeType == 2 ? (
                <div className="col-4 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {ccTRReviewRemark ? (
                      <>
                        {/* <option value={7}>Send to CC</option>
                                <option value={4}>Reply to Client</option> */}

                        <option value={16}>Forward to TL</option>
                        <option value={18}>Reply to Client</option>
                        <option value={20}>Send to TR</option>
                        <option value={23}>Send to CC</option>
                      </>
                    ) : (
                      <>
                        <option value={16}>Forward to TL</option>
                        <option value={18}>Reply to Client</option>
                        <option value={20}>Send to TR</option>
                        <option value={23}>Send to CC</option>
                        
                      </>
                    )}
                  </select>
                </div>
              ) : (
                ""
              )}
            </div>

            <div>
              <p className="lightgreen fw-bold my-3 fs-6">
                Team Member Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={ccATReviewRemark}
                  name="cctlremarks"
                  dangerouslySetInnerHTML={{ __html: ccATReviewRemark }}
                  onChange={handleATRemarksChange}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {ccTLReviewRemark && (
              <div>
                <p className="lightgreen fw-bold my-3 fs-6">
                  Team Leader Remarks
                </p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={ccTLReviewRemark}
                    name="cctlremarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: ccTLReviewRemark }}
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            {ccReviewRemark && (
              <div className="col-12 border-0" style={{ marginTop: "80px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">CC Remarks</p>
                <hr className="mb-3 lightgreen" />

                <div className="row mt-4">
                  <span>
                    {kickoutStatus === 15 ? (
                      <Alert
                        className="col-12"
                        message="Clarification Required"
                        banner
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="row mt-4">
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={ccReviewRemark}
                      name="ccremarks"
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: ccReviewRemark }}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="container mt-4 d-flex justify-content-end pr-0">
              <button
                className="btn px-3 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
            {loading && <div className="loader"></div>}
          </form>
        </>
      ) : kickoutStatus == 19 || kickoutStatus == 21 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmitAgain}
            encType="multipart/form-data"
          >
            <div className="cc-review1">
              <div className="cc-review2">
                <span className="cc-review3">CC Reviewed By </span>
                <select
                  id="list_of_users"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="list_of_users"
                  value={listItem}
                  readOnly={true}
                  disabled
                  style={{ flex: "1" }}
                >
                  <option value={""}>Select</option>
                  {listOfUsers.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Document Name
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Upload Document
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Uploaded Document
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    FVR Report
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file1} setFile={setFile1} />

                  <td>
                    <a href={fvrName} target="_blank">
                      {fvr}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    ER
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file2} setFile={setFile2} />

                  <td>
                    <a href={erName} target="_blank">
                      {er}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    PDD/MR
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file3} setFile={setFile3} />

                  <td>
                    <a href={pddName} target="_blank">
                      {pdd}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">IRR</td>

                  <FileUploaderComponent file={file4} setFile={setFile4} />

                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">5</td>

                  <td className="text-center lightgreen p-3">CPA</td>

                  <FileUploaderComponent file={file5} setFile={setFile5} />

                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">6</td>

                  <td className="text-center lightgreen p-3">
                    Audit Finding
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file6} setFile={setFile6} />

                  <td>
                    <a href={auditFindingName} target="_blank">
                      {auditFinding}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">7</td>

                  <td className="text-center lightgreen p-3">Other Document</td>

                  <FileUploaderComponent file={file7} setFile={setFile7} />

                  <td>
                    <a target="_blank" href={otherName}>
                      {other}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row">
              <div className="col-4 my-4">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Choose Change Type
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="changetype"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="change"
                  value={changeType}
                  disabled
                  required
                  onChange={(e) => {
                    setChangeType(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value={1}>Minor Changes</option>
                  <option value={2}>Major Changes</option>
                </select>
              </div>

              {changeType == 1 ? (
                <div className="col-4 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {/* <option value={7}>Send to CC</option>
                          <option value={4}>Reply to Client</option> */}
                  
                    <option value={16}>Forward to TL</option>
                    <option value={18}>Reply to Client</option>
                    <option value={23}>Send to CC</option>
                  </select>
                </div>
              ) : kickoutStatus == 19 && changeType == 2 ? (
                <div className="col-4 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={16}>Forward to TL</option>
                    <option value={18}>Reply to Client</option>
                    <option value={20}>Send to TR</option>
                    <option value={23}>Send to CC</option>
                    
                  </select>
                </div>
              ) : (
                <div className="col-4 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={16}>Forward to TL</option>
                    <option value={18}>Reply to Client</option>
                    <option value={20}>Send to TR</option>

                    {/* <option value={8}>Send to TR</option> */}
                  </select>
                </div>
              )}
            </div>

            <div>
              <p className="lightgreen fw-bold my-3 fs-6">
                Team Member Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={ccATReviewRemark}
                  name="cctlremarks"
                  dangerouslySetInnerHTML={{ __html: ccATReviewRemark }}
                  onChange={handleATRemarksChange}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {ccTLReviewRemark && (
              <div className="col-12 border-0" style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">
                  Team Leader Remarks
                </p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={ccTLReviewRemark}
                    name="cctlremarks"
                    dangerouslySetInnerHTML={{ __html: ccTLReviewRemark }}
                    // onChange={handleRemarksChange}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}
            {/* {kickoutStatus == 19 ? */}
            {ccReviewClientRemark && (
              <div className="col-12 border-0" style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={ccReviewClientRemark}
                    name="cctlremarks"
                    dangerouslySetInnerHTML={{ __html: ccReviewClientRemark }}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            {/* {kickoutStatus == 21 ? */}
            {ccTRReviewRemark && (
              <div className="col-12 border-0" style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">
                  Technical Reviewer Remarks
                </p>
                <hr className="mb-3 lightgreen" />
                {/* <div className="row my-4">
                    <span>
                      <Alert
                        className="col-12"
                        message="Clarification Required"
                        banner
                      />
                    </span>
                  </div> */}
                <div>
                  <ReactQuill
                    theme="snow"
                    value={ccTRReviewRemark}
                    name="cctlremarks"
                    dangerouslySetInnerHTML={{ __html: ccTRReviewRemark }}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            {ccReviewRemark && (
              <div style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">CC Remarks</p>
                <hr className="mb-3 lightgreen" />

                <div>
                  <span>
                    {kickoutStatus === 15 ? (
                      <Alert
                        className="col-12"
                        message="Clarification Required"
                        banner
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <ReactQuill
                      theme="snow"
                      value={ccReviewRemark}
                      name="ccremarks"
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: ccReviewRemark }}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="container mt-4 d-flex justify-content-end pr-0">
              <button
                className="btn px-3 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
            {loading && <div className="loader"></div>}
          </form>
        </>
      ) : kickoutStatus == 22 || kickoutStatus == 17 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            encType="json/form-data"
            onSubmit={handleSubmitAgain}
          >
            <div className="cc-review1">
              <div className="cc-review2">
                <span className="cc-review3">CC Reviewed By </span>
                <select
                  id="list_of_users"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="list_of_users"
                  value={listItem}
                  readOnly={true}
                  disabled
                  style={{ flex: "1" }}
                >
                  <option value={""}>Select</option>
                  {listOfUsers.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Document Name
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Upload Document
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Uploaded Document
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    FVR Report
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file1} setFile={setFile1} />

                  <td>
                    <a href={fvrName} target="_blank">
                      {fvr}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    ER
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file2} setFile={setFile2} />

                  <td>
                    <a href={erName} target="_blank">
                      {er}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    PDD/MR
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file3} setFile={setFile3} />

                  <td>
                    <a href={pddName} target="_blank">
                      {pdd}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">IRR</td>

                  <FileUploaderComponent file={file4} setFile={setFile4} />

                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">5</td>

                  <td className="text-center lightgreen p-3">CPA</td>

                  <FileUploaderComponent file={file5} setFile={setFile5} />

                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">6</td>

                  <td className="text-center lightgreen p-3">
                    Audit Finding
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <FileUploaderComponent file={file6} setFile={setFile6} />

                  <td>
                    <a href={auditFindingName} target="_blank">
                      {auditFinding}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">7</td>

                  <td className="text-center lightgreen p-3">Other Document</td>

                  <FileUploaderComponent file={file7} setFile={setFile7} />

                  <td>
                    <a target="_blank" href={otherName}>
                      {other}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row">
              <div className="col-4 my-4">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Choose Change Type
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="changetype"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="change"
                  value={changeType}
                  disabled
                  required
                  onChange={(e) => {
                    setChangeType(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value={1}>Minor Changes</option>
                  <option value={2}>Major Changes</option>
                </select>
              </div>
              <div className="col-3 my-4">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  required
                  onChange={(e) => {
                    setMember(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {/* <option value={7}>Send to CC</option>*/}
                  {/* <option value={20}>Send to TR</option>  */}
                  <option value={18}>Reply to Client</option>
                  <option value={16}>Forward to TL</option>
                  {/* <option value={20}>Send to TR</option> */}
                  <option value={23}>Send to CC</option>
                </select>
              </div>
            </div>

            <div>
              <p className="lightgreen fw-bold my-3 fs-6">
                Team Member Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={ccATReviewRemark}
                  name="cctlremarks"
                  dangerouslySetInnerHTML={{ __html: ccATReviewRemark }}
                  onChange={handleATRemarksChange}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {ccTLReviewRemark && (
              <div style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">
                  Team Leader Remarks
                </p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={ccTLReviewRemark}
                    name="cctlremarks"
                    readOnly={true}
                    // onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            {
              // kickoutStatus == 24 || (kickoutStatus == 23 && ccTRReviewRemark != null) ?
              ccReviewClientRemark && (
                <div style={{ marginTop: "50px" }}>
                  <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
                  <hr className="mb-3 lightgreen" />
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={ccReviewClientRemark}
                      name="cctlremarks"
                      dangerouslySetInnerHTML={{ __html: ccReviewClientRemark }}
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )
            }
            {/* {(kickoutStatus == 3 && ccTRReviewRemark != null) ? */}
            {ccTRReviewRemark && (
              <div style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">
                  Technical Reviewer Remarks
                </p>
                <hr className="mb-3 lightgreen" />
                {/* <Alert
                    className="col-12 my-3"
                    message="Approved"
                    type="success"
                    showIcon
                  /> */}
                <div>
                  <ReactQuill
                    theme="snow"
                    value={ccTRReviewRemark}
                    name="cctlremarks"
                    dangerouslySetInnerHTML={{ __html: ccTRReviewRemark }}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            {ccReviewRemark && (
              <div style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">CC Remarks</p>
                <hr className="mb-3 lightgreen" />

                <div>
                  <span>
                    {kickoutStatus === 15 ? (
                      <Alert
                        className="col-12"
                        message="Clarification Required"
                        banner
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <ReactQuill
                      theme="snow"
                      value={ccReviewRemark}
                      name="ccremarks"
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: ccReviewRemark }}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="container mt-4 d-flex justify-content-end pr-0">
              <button
                className="btn px-3 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
            {loading && <div className="loader"></div>}
          </form>
        </>
      ) : (
        <form
          method="post"
          encType="json/form-data"
          onSubmit={handleSubmitAgain}
        >
          <div className="cc-review1">
            <div className="cc-review2">
              <span className="cc-review3">CC Reviewed By </span>
              <select
                id="list_of_users"
                className="form-select borderlightgreen form-select-sm"
                aria-label="Default select example"
                name="list_of_users"
                value={listItem}
                readOnly={true}
                disabled
                style={{ flex: "1" }}
              >
                <option value={""}>Select</option>
                {listOfUsers.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <table className="table table-bordered  table-hover">
            <thead>
              <tr>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  S.No
                </th>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Document Name
                </th>

                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Uploaded Document
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">1</td>

                <td className="text-center lightgreen p-3">
                  FVR Report
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={fvrName} target="_blank">
                    {fvr}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">2</td>

                <td className="text-center lightgreen p-3">
                  ER
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={erName} target="_blank">
                    {er}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">3</td>

                <td className="text-center lightgreen p-3">
                  PDD/MR
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={pddName} target="_blank">
                    {pdd}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">4</td>

                <td className="text-center lightgreen p-3">IRR</td>

                <td>
                  <a href={rrName}>{rr}</a>
                </td>
              </tr>

              <tr>
                <td class="text-center">5</td>

                <td className="text-center lightgreen p-3">CPA</td>

                <td>
                  <a href={cpaName}>{cpa}</a>
                </td>
              </tr>

              <tr>
                <td class="text-center">6</td>

                <td className="text-center lightgreen p-3">
                  Audit Finding
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={auditFindingName} target="_blank">
                    {auditFinding}
                  </a>
                </td>
              </tr>

              <tr>
                <td class="text-center">7</td>

                <td className="text-center lightgreen p-3">Other Document</td>

                <td>
                  <a target="_blank" href={otherName}>
                    {other}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          {kickoutStatus < 24 ? (
            kickoutStatus != 14 && ccATReviewRemark ? (
              <div className="row">
                <div className="col-4 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Choose Change Type
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="changetype"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="change"
                    value={changeType}
                    required
                    disabled
                    onChange={(e) => {
                      setChangeType(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={1}>Minor Changes</option>
                    <option value={2}>Major Changes</option>n
                  </select>
                </div>

                <div className="col-4 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    disabled
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    
                    <option value={16}>Forward to TL</option>
                    <option value={23}>Send to CC</option>
                    <option value={18}>Reply to Client</option>
                    <option value={20}>Send to TR</option>
                  </select>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {ccATReviewRemark && (
            <div className="col-12 border-0" style={{ marginTop: "50px" }}>
              <p className="lightgreen fw-bold my-3 fs-6">
                Team Member Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={ccATReviewRemark}
                  name="cctlremarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: ccATReviewRemark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          )}

          {ccTLReviewRemark && (
            <div className="col-12 border-0" style={{ marginTop: "50px" }}>
              <p className="lightgreen fw-bold my-3 fs-6">
                Team Leader Remarks
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={ccTLReviewRemark}
                  name="cctlremarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: ccTLReviewRemark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          )}

          {
            // kickoutStatus == 24 || (kickoutStatus == 23 && ccTRReviewRemark != null) ?
            ccReviewClientRemark && (
              <div className="col-12 border-0 " style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={ccReviewClientRemark}
                    name="cctlremarks"
                    dangerouslySetInnerHTML={{ __html: ccReviewClientRemark }}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )
          }

          {
            // kickoutStatus == 24 || kickoutStatus == 18 || (kickoutStatus == 19 && ccTRReviewRemark != null) ?
            ccTRReviewRemark && (
              <div className="col-12 border-0" style={{ marginTop: "50px" }}>
                <p className="lightgreen fw-bold my-3 fs-6">
                  Technical Reviewer Remarks
                </p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={ccTRReviewRemark}
                    name="cctlremarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: ccTRReviewRemark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )
            // :
            // ''
          }

          {/* {
                    kickoutStatus == 21 || kickoutStatus == 24 ?

                      <div
                        className="col-12 border-0 mx-auto"
                        style={{ marginTop: "50px" }}
                      >
                        <p className="lightgreen fw-bold my-3 fs-6">CC Remarks</p>
                        <hr className="mb-3 lightgreen" />

                        <div >
                          <span>
                            {tlCCReviewStatus === 11 ? (
                              <Alert
                                className="col-12"
                                message="Approved"
                                type="success"
                                showIcon
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <ReactQuill
                              theme="snow"
                              value={ccReviewRemark}
                              name="ccremarks"
                              readOnly={true}
                              dangerouslySetInnerHTML={{ __html: ccReviewRemark }}

                              style={{

                                height: "20vh",
                                marginBottom: "2rem",
                              }}
                              className="mb-5"
                            />
                          </div>
                        </div>
                      </div>
                      :
                      ''
                  } */}

          {ccReviewRemark && (
            <div className="col-12 border-0" style={{ marginTop: "50px" }}>
              <p className="lightgreen fw-bold my-3 fs-6">CC Remarks</p>
              <hr className="mb-3 lightgreen" />

              {/* <div >
                    <span>
                      {kickoutStatus === 15 ? (
                        <Alert
                          className="col-12"
                          message="Clarification Required"
                          banner
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </div> */}
              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={ccReviewRemark}
                    name="ccremarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: ccReviewRemark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          )}

          {loading && <div className="loader"></div>}
        </form>
      )}
    </>
  );
};

export default ATKickoutCCRound;
