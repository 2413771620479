import { Input, Table, Tabs, DatePicker, Button, Select, Tooltip as Tip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  faFileCircleQuestion,
  faFileCircleCheck,
  faFileArrowDown,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  API_HEADER,
  getDashboardData,
  getAllProposals,
  getCountryList,
  get_client_name_url,
  get_sectoralscope_url,
  get_proposal_detail_url,
  get_program_url
} from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import LegendTable from "../LegendTable";
import ProposalNameWithDelay from "../Misc/ProposalNameWithDelay";

const { Option } = Select;

export default function SDash() {
  const mail_data = JSON.parse(localStorage.getItem("mail_reminder"));
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [proposal_received_pt, setProposal_received_pt] = useState(0);
  const [proposal_under_nego, setProposal_under_nego] = useState(0);
  const [dealLost, setDealLost] = useState(0);
  const [revision, setRevision] = useState(0);


  const [signed_contract, setSigned_contract] = useState(0);
  let [loader, Setloader] = useState(false);

  const [statuskey, setStatus] = useState(9);
  const [alldata, setAlldata] = useState([]);

  const [activeKey, setActiveKey] = useState("1");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const getDashData = async () => {
    try {
      const result = await axios.get(`${getDashboardData}`, API_HEADER);
      const dashboard = result.data.dashboard;
      setProposal_received_pt(dashboard.status9);
      setProposal_under_nego(dashboard.status10);
      setSigned_contract(dashboard.status12);
      setDealLost(dashboard.status11);
      setRevision(dashboard.status13);
    } catch (error) {
    }
  };

  const allData = async () => {
    try {
      let payload = {
        status: statuskey,
        page: pagination.current,
        limit: pagination.pageSize,
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
        country: country ? country : null,
        client_id: client_id ? client_id : null,
        scope: scope ? scope : null,
        program: program ? program : null,
        search: search ? search : null,
      };
      const response = await axios.post(
        `${getAllProposals}`,
        payload,
        API_HEADER
      );
      setAlldata(response.data.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.count,
      }));

      Setloader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleTabChange = (key) => {
    setFromDate(null);
    setToDate(null);
    setCountry(null);
    setClient_id(null);
    setScope(null);
    setSearch(null);
    setProgram(null)
    setActiveKey(key);

    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));

    if (key == 1) {
      setStatus(9);
    } else if (key == 2) {
      setStatus(10);
    } else if (key == 3) {
      setStatus(12);
    }
    else if (key == 4) {
      setStatus(11);
    }
    else if (key == 5) {
      setStatus(13);
    }

    Setloader(true);
  };

  useEffect(() => {
    allData();
  }, [loader]);

  useEffect(() => {
    getDashData();
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    Setloader(true);
  };
  const handleTmsActions = async (record) => {
    const payload = {
      proposal_id: record.proposal_id,
    };

    const response = await axios.post(
      `${get_proposal_detail_url}`,
      payload,
      API_HEADER
    );
    const data = response.data.record;
    navigate("/tmsactions", { state: { data } });
  };

  const dateFormat = "DD/MM/YYYY";
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [program, setProgram] = useState(null);
  const [country, setCountry] = useState(null);
  const [client_id, setClient_id] = useState(null);
  const [search, setSearch] = useState(null);
  const [programList, setProgramList] = useState([]);

  const handleSearch = (value) => {
    setSearch(value);
    Setloader(true);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    Setloader(true);
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    Setloader(true);
  };

  const handleSearchByDateRange = (value) => {
    const currentDate = moment();
    if (fromDate && fromDate.isAfter(currentDate)) {
      toast.error("From date cannot be a future date");
    } else if (toDate && toDate.isAfter(currentDate)) {
      toast.error("To date cannot be a future date");
    } else if (fromDate && toDate && fromDate.isAfter(toDate)) {
      toast.error("From date cannot be greater than to date");
    } else {
      Setloader(true);
    }
  };
  const handleClientNameSearch = (value) => {
    setClient_id(value);
    Setloader(true);
  };

  const handleCountrySearch = (value) => {
    setCountry(value);
    Setloader(true);
  };
  const handleScopeSearch = (value) => {
    setScope(value);
    Setloader(true);
  };
  const handleProgramSearch = (value) => {
    setProgram(value);
    Setloader(true);
  };

  const handleSearchAll = (value) => {
    setSearch(value);
    Setloader(true);
  };

  const [scope, setScope] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [scopeList, setScopeList] = useState([]);
  const [clientname, setClientname] = useState([]);

  const getCountry = async () => {
    try {
      const result = await axios.get(`${getCountryList}`);
      setCountryList(result.data.data);
    } catch (error) {
    }
  };
  const getClientname = async () => {
    try {
      const result = await axios.get(`${get_client_name_url}`);
      setClientname(result.data.data);
    } catch (error) {
    }
  };
  const getScope = async () => {
    try {
      const result = await axios.get(`${get_sectoralscope_url}`);
      setScopeList(result.data.data);
    } catch (error) {
    }
  };

  const getProgram = async () => {
    try {
      const result = await axios.get(`${get_program_url}`);
      setProgramList(result.data.data);
    } catch (error) {

    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getCountry();
    getClientname();
    getScope();
    getProgram()
  }, []);

  const columnProposalReceivedPT = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Proposal Date
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.created_at.slice(0, 10)}
          // </span>
          <Tip title={record.created_at.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.created_at.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)) ? 1 : (record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      fixed: "left",
      render: (text, record) => {
        return (
          // <span className="text-capitalize font14px ">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.earthood_id}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 130,
      render: (text, record) => (

        <ProposalNameWithDelay record={record}/>

      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (

          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.program_id) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.program_id}
            // </span>
            <Tip title={record.program_id}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_id}
          </span>
        </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 120,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.country}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <EditOutlined
          style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
          onClick={() => handleTmsActions(record)}
        />
      ),
    },
  ];

  const columnProposalSent = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Submission Date
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.created_at.slice(0, 10)}
          // </span>
          <Tip title={record.created_at.slice(0, 10)}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.created_at.slice(0, 10)}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)) ? 1 : (record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          // <span className="text-capitalize font14px ">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.earthood_id}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 130,
      render: (text, record) => (
        <ProposalNameWithDelay record={record}/>
      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 130,
      render: (text, record) => {
        return (

          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.program_id) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.program_id}
            // </span>
            <Tip title={record.program_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.program_id}
            </span>
          </Tip>
            
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 120,
      render: (text, record) => {
        if (record.sector) {
          return (

            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.scope > record2.scope) ? 1 : (record1.scope === record2.scope) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.country}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <EditOutlined
          style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
          onClick={() => handleTmsActions(record)}
        />
      ),
    },
  ];

  const columnSignedContract = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",

      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Contract sign Date
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.updated_at.slice(0, 10)}
          // </span>
          <Tip title={record.updated_at.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.updated_at.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.updated_at.slice(0, 10) > record2.updated_at.slice(0, 10)) ? 1 : (record1.updated_at.slice(0, 10) === record2.updated_at.slice(0, 10)) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          // <span className="text-capitalize font14px">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },

      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          <Tip title={record.project_name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,
      render: (text, record) => {
        return (
          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.program_id) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.program_id}
            // </span>
            <Tip title={record.program_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.program_id}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
      }
    },


    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          sectoral Scope
        </span>
      ),
      width: 120,
      render: (text, record) => {
        if (record.sector) {
          return (

            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.country}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (record) => (
        <EyeOutlined
          style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
          onClick={() => handleTmsActions(record)}
        />
      ),
    },
  ];

  const columnDealLost = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Contract sign Date
        </span>
      ),
      width: 130,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.updated_at.slice(0, 10)}
          // </span>
          <Tip title={record.updated_at.slice(0, 10)}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.updated_at.slice(0, 10)}
              </span>
            </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.updated_at.slice(0, 10) > record2.updated_at.slice(0, 10)) ? 1 : (record1.updated_at.slice(0, 10) === record2.updated_at.slice(0, 10)) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          // <span className="text-capitalize font14px">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.earthood_id}
              </span>
            </Tip>
        );
      },

      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 130,
      render: (text, record) => {
        return (
          <Tip title={record.project_name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (

          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.program_id) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.program_id}
            // </span>
            <Tip title={record.program_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.program_id}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 120,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.country}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <EyeOutlined
          style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
          onClick={() => handleTmsActions(record)}
        />
      ),
    },
  ];

  const columnRevisedProjects = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Contract sign Date
        </span>
      ),
      width: 130,
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.updated_at.slice(0, 10)}
          // </span>
          <Tip title={record.updated_at.slice(0, 10)}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.updated_at.slice(0, 10)}
              </span>
            </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.updated_at.slice(0, 10) > record2.updated_at.slice(0, 10)) ? 1 : (record1.updated_at.slice(0, 10) === record2.updated_at.slice(0, 10)) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          // <span className="text-capitalize font14px">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.earthood_id}
              </span>
            </Tip>
        );
      },

      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 130,
      render: (text, record) => {
        return (
          <Tip title={record.project_name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (

          <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.program_id) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.program_id}
            // </span>
            <Tip title={record.program_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.program_id}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: 120,
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.country}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <EditOutlined
          style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
          onClick={() => handleTmsActions(record)}
        />
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey="1"
              centered
              activeKey={activeKey}
              onChange={handleTabChange}
            >
           
              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 tabactivecolor  tab_dashboard_size5tab ${activeKey == 1 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap">
                      Proposal received
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {proposal_received_pt}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileArrowDown}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>

                    </div>
                  </div>
                }
                key="1"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                              Proposal received
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center p-2">

                          <div className="col-sm-4 col-md-3 col-lg-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                Program
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select program"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleProgramSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>

                                {programList.map((program, index) => (
                                  <Option
                                    key={index}
                                    value={program.id}
                                    label={program.program_name}
                                  >
                                    {program.program_name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          {/* <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div> */}
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-3 col-md-3 col-lg-3  mt-3 justify-content-end" >
                          <div className="mb-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              className="rounded-2"
                            />
                            </div>
                            </div>

                        </div>
                      </div>

                      <Table
                        scroll={{
                          x: 1000,
                        }}
                        columns={columnProposalReceivedPT}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        className=" border-1 border ronded-0 border-subtle-light px-0"
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor    tab_dashboard_size5tab ${activeKey == 2 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Under negotiation
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {proposal_under_nego}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileCircleQuestion}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="2"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                              Proposal under negotiation
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center p-2">

                          <div className="col-sm-4 col-md-3 col-lg-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                Program
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select program"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleProgramSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>

                                {programList.map((program, index) => (
                                  <Option
                                    key={index}
                                    value={program.id}
                                    label={program.program_name}
                                  >
                                    {program.program_name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          {/* <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div> */}
                          <div className="col-sm-4 col-md-1 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-4 col-lg-3 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"
                            />
                          </div>
                        </div>
                      </div>
                      <Table
                        scroll={{
                          x: 1000,
                        }}
                        className=" border-1 border ronded-0 border-subtle-light px-0"
                        columns={columnProposalSent}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor    tab_dashboard_size5tab ${activeKey == 3 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Signed contract
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {signed_contract}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="3"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                              Signed contract
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center p-2">

                          <div className="col-sm-4 col-md-3 col-lg-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                Program
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select program"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleProgramSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>

                                {programList.map((program, index) => (
                                  <Option
                                    key={index}
                                    value={program.id}
                                    label={program.program_name}
                                  >
                                    {program.program_name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          {/* <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div> */}
                          <div className="col-sm-4 col-md-1 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-4 col-lg-3 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"
                            />                          </div>

                        </div>
                      </div>

                      <Table
                        className=" border-1 border ronded-0 border-subtle-light px-0"
                        scroll={{
                          x: 1000,
                        }}
                        columns={columnSignedContract}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor    tab_dashboard_size5tab ${activeKey == 4 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Deals Lost
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {dealLost}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="4"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                              Deals Lost
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center p-2">

                          <div className="col-sm-4 col-md-3 col-lg-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                Program
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select program"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleProgramSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>

                                {programList.map((program, index) => (
                                  <Option
                                    key={index}
                                    value={program.id}
                                    label={program.program_name}
                                  >
                                    {program.program_name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          {/* <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                From Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                To Recd. Date
                              </label>
                              <DatePicker
                                onChange={handleToDateChange}
                                placeholder="To Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                              />
                            </div>
                          </div> */}
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-4 col-lg-3 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"
                            />
                          </div>

                        </div>
                      </div>

                      <Table
                        className=" border-1 border ronded-0 border-subtle-light px-0"
                        scroll={{
                          x: 1000,
                        }}
                        columns={columnDealLost}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor    tab_dashboard_size5tab ${activeKey == 5 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                    Proposal Under Revision
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {revision}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="5"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="bg-white border-0 shadow-sm rounded-top-3">
                        <div className="row border-0">
                          <div className="col-12">
                            <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                              Proposal Under Revision
                            </p>
                          </div>
                        </div>
                        <div className="row gx-3 align-items-center p-2">

                          <div className="col-sm-4 col-md-3 col-lg-3">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle     fw-bold font12px">
                                Client Name
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select client name"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleClientNameSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {clientname.map((client, index) => (
                                  <Option
                                    key={index}
                                    value={client.id}
                                    label={client.name}
                                  >
                                    {client.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                Country
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select country"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleCountrySearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>
                                {countryList.map((country, index) => (
                                  <Option
                                    key={index}
                                    value={country.id}
                                    label={country.name}
                                  >
                                    {country.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-2">
                            <div className="mb-3">
                              <label className="text-capitalize textcolumntitle    fw-bold font12px">
                                Program
                              </label>
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select program"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleProgramSearch}
                                style={{ width: "100%", }}
                                className="rounded-2"
                              >
                                <Option value="">Select</Option>

                                {programList.map((program, index) => (
                                  <Option
                                    key={index}
                                    value={program.id}
                                    label={program.program_name}
                                  >
                                    {program.program_name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-2">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-4 col-lg-3 mt-3">
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"
                            />
                          </div>

                        </div>
                      </div>

                      <Table
                        className=" border-1 border ronded-0 border-subtle-light px-0"
                        scroll={{
                          x: 1000,
                        }}
                        columns={columnRevisedProjects}
                        loading={loader}
                        dataSource={alldata}
                        rowKey="proposal_id"
                        pagination={pagination}
                        onChange={handleTableChange}
                        bordered
                      />
                      {
                        alldata != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
