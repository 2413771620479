// import { Input, Tooltip as Tip } from "antd";
// import { Table,  Select } from "antd";
// import axios from "axios";
// import React from "react";
// import { useEffect, useState } from "react";
// import {  useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import { API_HEADER, get_audit_plan_by_project } from "../config";
// import Footer from "./Footer";

// const AuditPlan = () => {
//       const { id } = useParams();
//     console.log("id:",id)
// 	const [dataSource, setDataSource] = useState([]);
// 	const [loadData, setloadData] = useState(false);
// 	const [loader, setLoader] = useState(false);

// 	const [pagination, setPagination] = useState({
// 		current: 1,
// 		pageSize: 10,
// 		total: 0,
// 	});

// 	const handleTableChange = (pagination, filters, sorter) => {
// 		setPagination(pagination);
// 		setloadData(true);
// 	};


// 	const columns = [
// 		{
// 			title: (
// 				<span className="text-capitalize textcolumntitle font14px  fw-bold">
// 					S.No
// 				</span>
// 			),
// 			dataIndex: "proposal_id",
// 			width: 60,
// 			render: (text, record, index) => {
// 				const pageIndex = (pagination.current - 1) * pagination.pageSize;
// 				return pageIndex + index + 1;
// 			},
// 		},
// 		{
// 			title: (
// 				<span className="text-capitalize textcolumntitle font14px  fw-bold">
// 					Audit Plan
// 				</span>
// 			),
// 			width: 180,

// 			render: (text, record) => {
// 				return (
// 					<span className="text-capitalize  font14px  ">
// 						{record.earthood_id}
// 					</span>
// 				);
// 			},
// 			sorter: (record1, record2) => {
// 				return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
// 			},
// 		},
// 		{
// 			title: (
// 				<span className="text-capitalize textcolumntitle font14px  fw-bold">
// 					Remarks
// 				</span>
// 			),
// 			width: 120,
// 			ellipsis: true,
// 			// dataIndex: "project_name",
// 			render: (text, record) => {
// 				return (
				
// 					<Tip title={record.project_name}>
// 					<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
// 					  {record.project_name}
// 					</span>
// 				  </Tip>
// 				);
// 			},
// 			sorter: (record1, record2) => {
// 				return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
// 			}
// 		},
		
// 		{
// 			title: (
// 				<span className="text-capitalize textcolumntitle font14px  fw-bold">
// 					Shared By
// 				</span>
// 			),
// 			width: 120,
// 			ellipsis: true,

// 			render: (text, record) => {
// 				return (
				
// 					<Tip title={record.client_name}>
// 					            <span className="text-capitalize font14px text-ellipsis">
// 								{record.client_name}
// 							  </span>
// 				    </Tip>
// 				);
// 			},
// 			sorter: (record1, record2) => {
// 				return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
// 			}
// 		},
// 		{
// 			title: (
// 				<span className="text-capitalize textcolumntitle font14px  fw-bold">
// 					Date
// 				</span>
// 			),
// 			width: 80,

// 			render: (text, record) => {
// 				return (
// 					<span className="text-capitalize font14px  ">
// 						{record.program_id}
// 					</span>
// 				);
// 			},
// 			sorter: (record1, record2) => {
// 				return (record1.program > record2.program) ? 1 : (record1.program === record2.program) ? 0 : -1
// 			}
// 		},
// 	];

// 	const allData = async () => {
// 		try {
// 			setLoader(true)

// 			const response = await axios.get(
// 				`${get_audit_plan_by_project}/${id}`,
// 				API_HEADER,
// 			);
// 			setDataSource(response.data.data);
// 			setloadData(false);

// 			setPagination((prevPagination) => ({
// 				...prevPagination,
// 				total: response.data.count,
// 			}));
// 			setLoader(false)

// 		} catch (error) {
// 		}
// 	};

// 	useEffect(() => {
// 		allData();
// 	}, [loadData]);

	

// 	return (
// 		<>
			
							

//             <div className="d-flex justify-content-end ">
//                   <button
//                     className="btn  fs-5"
//                     style={{
//                       backgroundColor: "#07b6af",
//                       color: "white",
//                     }}
//                     type="submit"
//                   >
//                     Share
//                   </button>
//                 </div>

// 								<div className=" border-2 border border-light-subtle p-0 rounded-3 mt-2 " >
// 									<div className="row border-0 ">
// 										<div className="col-12 ">
// 											<p className="text-black text-capitalize mx-2 font20px fw-bold p-2 rounded-top-3">
// 												Audit Plan List
// 											</p>
// 										</div>
// 									</div>


// 										<Table
// 										    scroll={{ x: 1000, }}
// 											className="border-1 border ronded-0 border-subtle-light px-0 m-3"
// 											columns={columns}
// 											dataSource={dataSource}
// 											rowKey="proposal_id"
// 											pagination={pagination}
// 											onChange={handleTableChange}
// 											loading={loader}
// 											bordered
// 										/>

// 								</div>

						
						
// 			<Footer />
// 		</>
// 	);
// };

// export default AuditPlan;



import { Input, Tooltip as Tip, Modal, Button, Form, Upload } from "antd";
import { Table, Select } from "antd";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_HEADER, get_audit_plan_by_project, save_audit_plan, BASE_DOCUMENT } from "../config";
import Footer from "./Footer";
import { UploadOutlined } from "@ant-design/icons";
import FileUploaderComponent from "../utlis/FileUploaderComponent";

const AuditPlan = ({project_status}) => {
  const { id } = useParams();
  console.log("id:", id);

  console.log("idssssssss:", project_status);
  const [dataSource, setDataSource] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [loader, setLoader] = useState(false);
    const [file, setFile] = useState('');
    const [remarksAudit, setRemarksAudit] = useState("");
    
  

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [form] = Form.useForm(); // Ant Design Form instance

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setLoadData(true);
  };

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

	const columns = [
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					S.No
				</span>
			),
			dataIndex: "proposal_id",
			width: 60,
			render: (text, record, index) => {
				const pageIndex = (pagination.current - 1) * pagination.pageSize;
				return pageIndex + index + 1;
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Audit Plan
				</span>
			),
			width: 180,

			render: (text, record) => {
				return (
                    <>
                    <a target="_blank" href={`${BASE_DOCUMENT}/projects/${record.project_id}/${record.audit_plan}`}>
                                      {record.audit_plan}
                    </a>
                    </>
				);
			},
			sorter: (record1, record2) => {
				return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Remarks
				</span>
			),
			width: 120,
			ellipsis: true,
			// dataIndex: "project_name",
			render: (text, record) => {
				return (
				
					<Tip title={record.remarks}>
					<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
					  {record.remarks}
					</span>
				  </Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
			}
		},
		
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px  fw-bold">
					Shared By
				</span>
			),
			width: 120,
			ellipsis: true,

			render: (text, record) => {
				return (
				
					<Tip title={record.user_name}>
					            <span className="text-capitalize font14px text-ellipsis">
								{record.user_name}
							  </span>
				    </Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
			}
		},
		{
            title: (
              <span className="text-capitalize textcolumntitle font14px  fw-bold">
                Date
              </span>
            ),
            width: 80,
            render: (text, record) => {
              const formatDate = (dateString) => {
                const date = new Date(dateString);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
              };
          
              return (
                <span className="text-capitalize font14px">
                  {formatDate(record.created_at)}
                </span>
              );
            },
            sorter: (record1, record2) => {
              return new Date(record1.created_at) - new Date(record2.created_at);
            }
          }
          
	];

  const allData = async () => {
    try {
      setLoader(true);

      const response = await axios.get(
        `${get_audit_plan_by_project}/${id}`,
        API_HEADER
      );
      console.log("responseresponse",response)
      setDataSource(response.data.records);
      setLoadData(false);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.count,
      }));
      setLoader(false);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    allData();
  }, [loadData]);

  const handleRemarksAuditChange = (e) => {
    setRemarksAudit(e.target.value);
  };

  const handleSubmit = async (e,) => {
    e.preventDefault()
    console.log("Form values:", remarksAudit,file);
    let payload = {
        project_id:id,
        remarks:remarksAudit,
        audit_plan:file
    }

    const formDataToSend = new FormData();

    formDataToSend.append("project_id", id);
    formDataToSend.append("remarks", remarksAudit);
    formDataToSend.append("audit_plan", file);
    console.log("payload",payload)
    try {
      const response = await axios.post(
        `${save_audit_plan}`,
        // payload,
        formDataToSend,
        CONFIG_Token2
      );
      console.log("response",response)
      toast.success("Audit Plan submitted successfully");
      setFile('')
      setRemarksAudit('')

      setIsModalOpen(false); 
      window.location.reload();
     
    } catch (error) {
      toast.error("Failed to submit Audit Plan");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end ">
        {/* <Button
          className="fs-5"
          style={{
            backgroundColor: "#07b6af",
            color: "white",
          }}
          onClick={() => setIsModalOpen(true)} // Open modal on click
        >
          Share
        </Button> */}
            {project_status < 4 ?
                   <button
                    className="btn  fs-5"
                    style={{
                      backgroundColor: "#07b6af",
                      color: "white",
                    }}
                    type="submit"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Share
                  </button>
                  :''
                      }      
                          </div>

      <div className="border-2 border border-light-subtle p-0 rounded-3 mt-2">
        <div className="row border-0">
          <div className="col-12 ">
            <p className="text-black text-capitalize mx-2 font20px fw-bold p-2 rounded-top-3">
              Audit Plan List
            </p>
          </div>
        </div>

        <Table
          scroll={{ x: 1000 }}
          className="border-1 border ronded-0 border-subtle-light px-0 m-3"
          columns={columns}
          dataSource={dataSource}
          rowKey="proposal_id"
          pagination={pagination}
          onChange={handleTableChange}
          loading={loader}
          bordered
        />
      </div>

      {/* Modal for sharing form */}
      <Modal
        title="Share Audit Plan"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          
        >
          {/* <Form.Item
            label="Remarks"
            name="remarks"
            rules={[{ required: true, message: "Please enter remarks" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item> */}

                                  <textarea
                                  placeholder="Add Remarks"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  id="exampleFormControlTextarea1"
                                  rows={3}
                                  value={remarksAudit}
                                  onChange={handleRemarksAuditChange}
                                >

                                </textarea>


          {/* <Form.Item
            label="Add Documents"
            name="documents"
            valuePropName="fileList"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
            rules={[{ required: true, message: "Please upload documents" }]}
          > */}
            <div className="mt-4">
           <FileUploaderComponent dynamicMaxSize={dynamicMaxSize} file={file} setFile={setFile} />
           </div>

          {/* </Form.Item> */}


          <div className="d-flex justify-content-end mt-4">
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#07b6af", border: "none" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      <Footer />
    </>
  );
};

export default AuditPlan;


