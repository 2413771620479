
import React, { useState, useEffect } from "react";
import { post_project_notes, get_project_notes } from "../config";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";

const AT_TLNotes = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [notes, setNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const fetchFindingData = async (id) => {
      try {
        const response = await axios.get(`${get_project_notes}/${id}`, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        const data = response.data;
        setData(data.records);
      } catch (error) {}
    };
    fetchFindingData(id);
  }, [id]);

  const handleNotes = (content) => {
    setNotes(content);
  };

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercent(percentCompleted);
    },
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()} - ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("project_id", id);
      formData.append("notes", notes);

      const response = await axios.post(
        `${post_project_notes}`,
        formData,
        CONFIG_Token2
      );
      if (!response.data.status) {
        toast.error(response.data.message);
      } else {
        toast.success("Notes Submitted Successfully");
        setNotes("");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    // <div style={{ maxHeight: "70vh", overflow: "hidden", border: "1px solid #ccc" }}>
    <>
      <div
        style={{ height: "400px", overflowY: "auto", border: "1px solid #ddd" }}
      >
        <table
          className="table table-bordered table-hover"
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <thead>
            <tr>
              <th
                className="form-label lightgreen fs-6 text-center table-heading-color"
                style={{ width: "10%" }}
              >
                S.No.
              </th>
              <th
                className="form-label lightgreen fs-6 text-center table-heading-color"
                style={{ width: "20%" }}
              >
                Added By
              </th>
              <th
                className="form-label lightgreen fs-6 text-center table-heading-color"
                style={{ width: "50%" }}
              >
                Notes Description
              </th>
              <th
                className="form-label lightgreen fs-6 text-center table-heading-color"
                style={{ width: "20%" }}
              >
                Date/Time
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.created_by}</td>
                  <td style={{ wordWrap: "break-word" }}>{item.notes}</td>
                  <td>{formatDate(item.created_at)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="row mt-4">
        <label className="form-label lightgreen fs-6 mb-2">Add Notes</label>
        <ReactQuill
          theme="snow"
          value={notes}
          onChange={handleNotes}
          style={{
            height: "20vh",
            marginBottom: "2rem",
          }}
        />
      </div>

      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-end my-3 col-12 pr-0">
            <button
              className="btn fs-5"
              style={{
                backgroundColor: "#07b6af",
                color: "white",
              }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default AT_TLNotes;
