import React, { useState, useEffect } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, get_client_name_url, get_scope_pa_poa_url, mail_reminder_url } from "../config";
import { get_scope_url } from "../config";
import { get_sectoralscope_url } from "../config";
import { get_program_url } from "../config";
import { get_country_url } from "../config";
import { get_assesment_url } from "../config";
import { contract_check_url } from '../config';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { API_HEADER } from '../config';
import AlternateContact from '../Components/AlternateContact';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { contract_upload_url } from '../config';
import logo from '../assets/logo1.png';
import { DatePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import '../App.css'
import FileUploaderComponent from '../utlis/FileUploaderComponent';
import LoaderComponent from '../utlis/LoderComponent';
const fileTypes = [
    "JPG",
    "JPEG",
    "PDF",
    "RAR",
    "XLS",
    "XLSX",
    "DOC",
    "DOCX",
    "ZIP",
    "XLSM"
];


const ClientLogin = () => {
    let { token } = useParams();

    const [fileContractSizeError, setFileContractSizeError] = useState(false)
    const [clientName, setClientName] = useState([]);
    const [myscope, setMyScope] = useState([]);
    const [selected, setSelected] = useState([]);
    const [mysectorscope, setMySectorScope] = useState([]);
    const [selectedSector, setSelectedSector] = useState([]);
    const [program, setProgram] = useState([]);
    const [country, setCountry] = useState([]);
    const [atlist, setAtList] = useState([]);
    // const [f23_doc, setF23_doc] = useState("");
    const [file, setFile] = useState('');
    const [remark, setRemark] = useState('');
    const [proposalid, setProposalId] = useState("");
    const [records, setRecords] = useState([{ name: '', email: '', mobile: '', designation: '' }]);
    const [f23name, setF23Name] = useState("");
    const [f23, setF23] = useState("");
    const [scope_PA_POA, setScope_PA_POA] = useState([]);
    const [formEdit, setformEdit] = useState(true);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);

    const [contractfile, setContractFile] = useState(null);
    const [dynamicMaxSize, setdynamicMaxSize] = useState(null)

      const [action, setAction] = useState("");
    

    const [formData, setFormData] = useState({
        project_name: '',
        earthood_id: '',
        client_id: '',
        client_name: '',
        country: '',
        program: '',
        program_id: '',
        implemented_fees: '',
        created_at: '',
        scope_pa: '',
        sectoral_scope: '',
        scope: [],
        sectoral_scope: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {

                let payload = {
                    "token": token
                }
                const response = await axios.post(
                    `${contract_check_url}`,
                    payload

                );
                if (response.data.status == false) {

                    navigate('/thanks');
                }

                setFormData({

                    project_name: response.data.data.project_name,
                    earthood_id: response.data.data.earthood_id,
                    client_id: response.data.data.client_id,
                    client_name: response.data.data.client_name,
                    country: response.data.data.country,
                    program: response.data.data.program,
                    program_id: response.data.data.program_id,
                    implemented_fees: response.data.data.implemented_fees,
                    created_at: response.data.data.proposal_date,
                    scope_pa: response.data.data.scope_pa,
                    sectoral_scope: selected,
                    scope: selected
                });

                let url3 = `${BASE_DOCUMENT}/documents/${response.data.data.earthood_id}/${response.data.data.f23_doc}`;
                setF23Name(url3);
                setF23(response.data.data.f23_doc)

                setProposalId(response.data.data.id)

                const scopes = response.data.data.scope.split(",").map(Number);
                const filteredScopes = myscope.filter((scope) =>
                    scopes?.includes(scope.value)
                );
                setSelected(filteredScopes);

                const sectorscopes = response.data.data.sectoral_scope.split(",").map(Number);
                const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
                    sectorscopes?.includes(sectorscope.value)
                );
                setSelectedSector(filteredSectorScopes);


            } catch (error) {
            }
        };

        fetchData();
    }, [mysectorscope, myscope]);

    useEffect(() => {

        const getSetting = async () => {
            const mail_reminder = await axios.get(`${mail_reminder_url}`);
            console.log('calling');
            localStorage.setItem('mail_reminder', JSON.stringify(mail_reminder.data.data));
            // const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
            // setdynamicMaxSize(my_file_maxSize.max_document_size)   
        }

        getSetting();

    }, [mysectorscope]);

    const addrecord = () => {
        if (records.length < 10) {
            setRecords([...records, { name: '', email: '', mobile: '', designation: '' }]);
        }
    };

    const handleRecordChange = (index, event) => {

        const { name, value } = event.target;
        const newPayments = records.map((Payment, idx) => {
            if (idx === index) {
                let updatedPayment = { ...Payment, [name]: value };

                return updatedPayment;
            }
            return Payment;
        });

        setRecords(newPayments);

    };

    const deleteRecord = (index) => {
        const newPayments = records.filter((_, idx) => idx !== index);
        setRecords(newPayments);
    };

    const handleRemarksChange = (content) => {
        setRemark(content);
    };

    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(false);

        if (((remark == null || remark == "<p><br></p>" || remark == "") || (file == null || file == '')) && action) {
            toast.error("Please upload the document,remarks and take action.");
        } else {

            try {
                setIsSubmitting(true);

                const formDataToSend = new FormData();
                {action == 12 ? formDataToSend.append('signed_contract', file) : formDataToSend.append('revision_attachments', file)};
                formDataToSend.append('remarks', remark);
                formDataToSend.append('proposal_id', proposalid);
                formDataToSend.append('status', action);
                // formDataToSend.append('revision_attachments', file);


                records.forEach((contact, index) => {
                    formDataToSend.append(`alternate_contact[${index}][name]`, contact.name);
                    formDataToSend.append(`alternate_contact[${index}][email]`, contact.email);
                    formDataToSend.append(`alternate_contact[${index}][mobile]`, contact.mobile);
                    formDataToSend.append(`alternate_contact[${index}][designation]`, contact.designation);
                });

                const response = await axios.post(`${contract_upload_url}`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setPercent(percentCompleted);
                    }
                });


                if (!response.data.status) {
                    toast.error("Please upload signed contract");
                } else {
                    toast.success("Information Uploaded Successfully");
                    navigate('/thanks')
                    setFile(null);
                    setRemark('');
                    setRecords([{ name: '', email: '', mobile: '', designation: '' }]);
                }

            } catch (error) {
                console.error('Error:', error);
            }
            finally {
                // setLoading(false);
                setIsSubmitting(false);

            }
        }
    };

    const fetchDataClientName = async () => {
        try {
            const responseclientname = await axios.get(`${get_client_name_url}`);
            setClientName(responseclientname.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    useEffect(() => {
        const fetchDataSope_PA_POA = async () => {
            try {
                const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

                setScope_PA_POA(responsescope.data.data);
            } catch (error) { }
        };

        fetchDataSope_PA_POA();
    }, []);

    useEffect(() => {
        const fetchDataScope = async () => {
            try {
                const responsescope = await axios.get(`${get_scope_url}`);

                setMyScope(
                    responsescope.data.data.map((scope) => ({
                        value: scope.id,
                        label: scope.sector_name,
                    }))
                );
            } catch (error) {

            }
        };

        fetchDataScope();
    }, []);

    useEffect(() => {
        const fetchSectoralScope = async () => {
            try {
                const responsesectoralscope = await axios.get(`${get_sectoralscope_url}`);

                setMySectorScope(
                    responsesectoralscope.data.data.map((scope) => ({
                        value: scope.id,
                        label: scope.scope,
                    }))
                );
            } catch (error) {

            }
        };

        fetchSectoralScope();
    }, []);

    const fetchDataProgram = async () => {
        try {
            const responseprogram = await axios.get(`${get_program_url}`);
            setProgram(responseprogram.data.data);
        } catch (error) {
        }
    };

    const fetchDataCountry = async () => {
        try {
            const responsecountry = await axios.get(`${get_country_url}`);
            setCountry(responsecountry.data.data);
        } catch (error) {
        }
    };


    const fetchDataTechnicalReviewer = async () => {
        try {
            const responseteamleader = await axios.get(`${get_assesment_url}`);
            setAtList(responseteamleader.data.data);
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchDataCountry();
        fetchDataProgram();
        fetchDataClientName();
        fetchDataTechnicalReviewer();

    }, []);



    const handleFileChange = (file) => {
        if (file.size <= dynamicMaxSize * 1024 * 1024) {
            setFileContractSizeError(false)
        }
        setFileContractSizeError(false)
        setFile(file);
    };

    const handleContractFileError = (file) => {
        setFileContractSizeError(true)
    }

    const handleActionChange = (e) => {
        setAction(e.target.value);
      };


      console.log("action ",action)

    return (
        <>
            <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

            <div className='container mx-auto formclient m-5'>
                <div className="row d-flex justify-content-between align-items-center p-2 formclientbg border-0 ">
                    <div className="col-6 mt-3">
                        <img src={logo} alt="earthood logo"  />
                    </div>
                    <div className="col-6 d-flex justify-content-end pe-5 ">
                        <h3 className='text-dark text-capitalize textcolorblue'> {formData.client_name.name}</h3>
                    </div>
                </div>
                <div className='row formclientbg p-5 position-relative mb-5'>
                    <div className="col-6">
                        <h4 className='display-6 py-2 px-3 text-black border border-white border-5 position-absolute sidenavbar_bg'>Contract Upload Form</h4>
                    </div>
                </div>
                <div className='container'>


                    <form className='' >

                        <div className="border-0 mx-4 col-12">
                            <p className="textlightgreen fw-bold mb-3 mt-5 fs-5 ">
                                Project Details
                            </p>
                            <hr className="mb-3 lightgreen" />
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-12 mb-4">
                                    <label htmlFor="projectname" className="form-label lightgreen fs-6">
                                        Project Name<span style={{ color: "red" }}>*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control borderlightgreen`}
                                        id="project_name"
                                        placeholder="Project Name"
                                        required
                                        name="project_name"
                                        disabled={true}
                                        value={formData.project_name}

                                    />

                                </div>
                                <div className="col-lg-4 col-md-4 col-12 mb-4">
                                    <label htmlFor="eid" className="form-label lightgreen fs-6">
                                        Earthood Id<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control borderlightgreen`}
                                        id="earthood_id"
                                        placeholder="Earthood Id"
                                        name="earthood_id"
                                        required
                                        disabled={true}
                                        value={formData.earthood_id}

                                    />

                                </div>
                                <div className="col-lg-4 col-md-4 col-12 mb-4">
                                    <label htmlFor="clientname" className="form-label lightgreen fs-6">
                                        Client Name<span style={{ color: "red" }}>*</span>
                                    </label>

                                    <select
                                        id="client_id"
                                        className="form-select borderlightgreen form-select-sm"
                                        aria-label="Default select example"
                                        name="client_id"
                                        disabled={true}
                                        value={formData.client_id}

                                    >
                                        <option value={""}>
                                            Select
                                        </option>
                                        {clientName.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-12 mb-4">
                                    <label
                                        htmlFor="country"
                                        className="form-label lightgreen fs-6"
                                        required
                                    >
                                        Project Country<span style={{ color: "red" }}>*</span>
                                    </label>

                                    <select
                                        id="country"
                                        className="form-select borderlightgreen form-select-sm"
                                        aria-label="Default select example"
                                        name="country"
                                        disabled={true}
                                        value={formData.country}

                                    >
                                        <option value={""}>
                                            Select
                                        </option>
                                        {country.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div className="col-lg-4 col-md-4 col-12 mb-4">
                                    <label htmlFor="Program" className="form-label lightgreen fs-6">
                                        Program<span style={{ color: "red" }}>*</span>
                                    </label>

                                    <select
                                        id="program"
                                        className="form-select borderlightgreen form-select-sm"
                                        aria-label="Default select example"
                                        name="program"
                                        disabled={true}
                                        value={formData.program}

                                    >
                                        <option value={""}>
                                            Select
                                        </option>
                                        {program.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.program_name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div className="col-lg-4 col-md-4 col-12 mb-4">
                                    <label htmlFor="Program Id" className="form-label lightgreen fs-6">
                                        Program Id
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control borderlightgreen`}
                                        id="program_id"
                                        placeholder="Program Id"
                                        required
                                        name="program_id"
                                        disabled={true}
                                        value={formData.program_id}

                                    />

                                </div>
                            </div>

                            <div className="row">
                                {/* <div className="col-lg-4 col-md-4 col-12 mb-4">
                                    <label
                                        htmlFor="Implementation Fees"
                                        className="form-label lightgreen fs-6"
                                    >
                                        Implementation Fees<span style={{ color: "red" }}>*</span>

                                    </label>
                                    <input
                                        type="number"
                                        className={`form-control borderlightgreen`}
                                        id="implemented_fees"
                                        placeholder="Implementation Fees"
                                        required
                                        name="implemented_fees"
                                        value={formData.implemented_fees}
                                        disabled={true}

                                    />

                                </div> */}
                                <div className="col-lg-4 col-md-4 col-12 mb-4">
                                    <label htmlFor="Proposal Date" className="form-label lightgreen fs-6">
                                        Proposal Date<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <DatePicker
                                        id="proposaldate"
                                        className={`form-control borderlightgreen custom-disabled-date-picker `}
                                        style={{ color: 'black' }}
                                        disabled
                                        format="DD/MM/YYYY"
                                        value={formData.created_at ? dayjs(formData.created_at) : null}

                                    />

                                </div>
                                <div className="col-lg-4 col-md-4 col-12 mb-3">
                                    <label
                                        htmlFor="Scope(PO/POA)"
                                        className="form-label lightgreen fs-6"
                                    >
                                        Scope(PA/POA)<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                        id="scope_pa"
                                        className="form-select borderlightgreen form-select-sm"
                                        aria-label="Default select example"
                                        name="scope_pa"
                                        value={formData.scope_pa}
                                        disabled={true}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                scope_pa: e.target.value,
                                            })
                                        }
                                    >
                                        <option value={""}>Select</option>
                                        {scope_PA_POA.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.scope}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-lg-4 col-md-4 col-12 mb-3">
                                    <label htmlFor="Scope" className="form-label lightgreen fs-6">
                                        Sectoral Scope<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <MultiSelect
                                        disabled={true}
                                        options={mysectorscope}
                                        value={selectedSector}
                                        onChange={setSelectedSector}
                                        labelledBy="Select"
                                        className='multi'
                                    />
                                </div>

                            </div>

                            <div className="row">


                                <div className="col-lg-4 col-md-4 col-12 mb-3">
                                    <label htmlFor="Scope" className="form-label lightgreen fs-6">
                                        Scope<span style={{ color: "red" }}>*</span>
                                    </label>

                                    <MultiSelect
                                        disabled={true}
                                        options={myscope}
                                        value={selected}
                                        onChange={setSelected}
                                        labelledBy="Select "
                                        className='multi'
                                    // style={{backgroundColor:'red'}}
                                    />

                                </div>
                                {/* adding */}


                                <div className="col-lg-4 col-md-4 col-12 mb-5">
                                    <label
                                        htmlFor="f23"
                                        className="form-label lightgreen fs-6"
                                    >
                                        Service Agreement (F23)
                                    </label>
                                    <div className='linkBox' style={{ background: "#e7e7e7" }}>
                                        <a
                                            target="_blank"
                                            href={f23name}
                                            className='linkBox_Ellipsis'
                                        >
                                            {f23}
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
                
                <div className="col-12 mx-4">
                <hr className="mb-3 lightgreen" />
                <div className='row'>
                  <span className="col-12 col-lg-4 col-md-4">
                    <input
                      type="radio"
                      id="Contract_signed"
                      name="contract"
                      value={12}
                      onChange={handleActionChange}
                      className=" text-success"
                      
                    />
                    <label className="text-success mx-1" htmlFor="approve">Contract Signed</label>
                  </span>

                  <span className="col-12  col-md-4 mx-3">
                    <input
                      type="radio"
                      id="revision"
                      name="contract"
                      value={13}
                      onChange={handleActionChange}
                      className="text-danger "
                    />
                    <label className="text-danger mx-1" htmlFor="clarification_required">
                      Revision
                    </label>
                  </span>
                  </div>
                  <hr className="mb-3 lightgreen" />
                </div>

                



{action == 13 ?
                <div className='container'>
                    <form onSubmit={handleSubmit}>

                        <div className="col-12 mx-4" >
                            <p className="textlightgreen fw-bold mb-3 fs-5">
                                Document and Remarks
                            </p>
                            <hr className="mb-3 lightgreen" />
                            <div className="row ">
                                <div className='col-8'>
                                    <label className="form-label lightgreen fs-6" htmlFor="remarks">Upload Revised Contract<span style={{ color: "red" }}>*</span></label>
                                    <div className="borderfileupload my-2">
                                        {/* <FileUploader
                                        handleChange={handleFileChange}
                                        name="signed_contract"
                                        types={fileTypes}
                                        multiple={false}
                                        required = {false}
                                        maxSize={dynamicMaxSize}
                                        onSizeError={handleContractFileError}
                                    
                                    /> */}
                                        <FileUploaderComponent file={file} setFile={setFile} />
                                    </div>

                                    {/* {fileContractSizeError ? <span className="text-danger">File size greater than {dynamicMaxSize}mb is not allowed</span> :<span>{file?file.name:''}</span>
                                } */}


                                </div>
                                {/* <div className='col-3 my-3'>
                                    <div className="borderfileupload p-lg-3 p-md-3 p-0 mx-auto linkBox text-center mt-3"  style={{ background: "#e7e7e7" }}>

                                        <a href='https://www.digisigner.com/free-electronic-signature/sign-document-online' target='_blank' className='linkBox_Ellipsis'>
                                            Upload Digital Signature
                                        </a>
                                    </div>
                                </div> */}

                            </div>
                            <div className="row my-3">
                                <div className="col-12">

                                    <label className="form-label lightgreen fs-6 mb-3" >Remarks<span style={{ color: "red" }}>*</span></label>

                                    <ReactQuill
                                        theme="snow"
                                        value={remark}
                                        onChange={handleRemarksChange}
                                        style={{ height: '20vh', marginBottom: '2rem' }}
                                        className='mb-5'
                                    />

                                </div>
                            </div>

                        </div>
                        {/* <div className="container mt-4 mx-4 my-5"  >

                            <p className='text-bold lightgreen fs-6 mt-2'>Alternate Contact Details</p>

                            <AlternateContact records={records} formdisabled={false} addrecord={addrecord} handleRecordChange={handleRecordChange} deleteRecord={deleteRecord} />

                        </div> */}
                        <div className="container">
                            <div className="row">
                                <div className='d-flex justify-content-end my-5 col-12'>
                                    <button className='btn px-3 fs-5' style={{ backgroundColor: '#07b6af', color: 'white' }} type="Submit">Submit</button>
                                </div>
                            </div>
                        </div>



                    </form>
                </div>
                :
                
                <div className='container'>
                <form onSubmit={handleSubmit}>

                    <div className="col-12 mx-4" >
                        <p className="textlightgreen fw-bold mb-3 fs-5">
                            Document and Remarks
                        </p>
                        <hr className="mb-3 lightgreen" />
                        <div className="row ">
                            <div className='col-8'>
                                <label className="form-label lightgreen fs-6" htmlFor="remarks">Upload Signed Contract<span style={{ color: "red" }}>*</span></label>
                                <div className="borderfileupload my-2">
                                    {/* <FileUploader
                                    handleChange={handleFileChange}
                                    name="signed_contract"
                                    types={fileTypes}
                                    multiple={false}
                                    required = {false}
                                    maxSize={dynamicMaxSize}
                                    onSizeError={handleContractFileError}
                                
                                /> */}
                                    <FileUploaderComponent file={file} setFile={setFile} />
                                </div>

                                {/* {fileContractSizeError ? <span className="text-danger">File size greater than {dynamicMaxSize}mb is not allowed</span> :<span>{file?file.name:''}</span>
                            } */}


                            </div>
                            <div className='col-3 my-3'>
                                <div className="borderfileupload p-lg-3 p-md-3 p-0 mx-auto linkBox text-center mt-3"  style={{ background: "#e7e7e7" }}>

                                    <a href='https://www.digisigner.com/free-electronic-signature/sign-document-online' target='_blank' className='linkBox_Ellipsis'>
                                        Upload Digital Signature
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className="row my-3">
                            <div className="col-12">

                                <label className="form-label lightgreen fs-6 mb-3" >Remarks<span style={{ color: "red" }}>*</span></label>

                                <ReactQuill
                                    theme="snow"
                                    value={remark}
                                    onChange={handleRemarksChange}
                                    style={{ height: '20vh', marginBottom: '2rem' }}
                                    className='mb-5'
                                />

                            </div>
                        </div>

                    </div>
                    <div className="container mt-4 mx-4 my-5"  >

                        <p className='text-bold lightgreen fs-6 mt-2'>Alternate Contact Details</p>

                        <AlternateContact records={records} formdisabled={false} addrecord={addrecord} handleRecordChange={handleRecordChange} deleteRecord={deleteRecord} />

                    </div>
                    <div className="container">
                        <div className="row">
                            <div className='d-flex justify-content-end my-5 col-12'>
                                <button className='btn px-3 fs-5' style={{ backgroundColor: '#07b6af', color: 'white' }} type="Submit">Submit</button>
                            </div>
                        </div>
                    </div>



                </form>
            </div>
                }

                <br />

            </div>
        </>

    );
};

export default ClientLogin;
