import React, { useState, useEffect } from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { client_finding_url } from "../config";
import { API_HEADER, BASE_DOCUMENT, get_client_name_url } from "../config";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";

const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const ClientDraftFinding = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  // const [remark, setRemark] = useState("");
  const [tlremark, setTLRemark] = useState("");
  const [atremark, setATRemark] = useState("");

  const [clientRemark, setClientRemark] = useState("");
  const [formData, setFormData] = useState({});
  const [rowCount, setRowCount] = useState(1);
  const [files, setFiles] = useState([]);
  const [evidenceList, setEvidenceList] = useState([]);
  const [dynamicname, setDynamicName] = useState([]);
  const [dynamic, setDynamic] = useState([]);
  const [fileFindingSizeError, setFileFindingSizeError] = useState(false);
  const [Findingfile, setFindingFile] = useState("");
  const [FindingfileName, setFindingFileName] = useState("");

  const [findingTlName, setFindingTlName] = useState(null);
  const [findingTl, setFindingTl] = useState("");
  const [findingClientName, setFindingClientName] = useState(null);
  const [findingClient, setFindingClient] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [findingStatus, setFindingStatus] = useState(null);

  const [filePDDSizeError, setFilePDDSizeError] = useState(false);
  const [fileERSizeError, setFileERSizeError] = useState(false);
  const [fileMRSizeError, setFileMRSizeError] = useState(false);
  const [fileIRRSizeError, setFileIRRSizeError] = useState(false);
  const [fileOtherSizeError, setFileOtherSizeError] = useState(false);
  const [fileCPASizeError, setFileCPASizeError] = useState(false);

  const [file3, setFile3] = useState("");
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const [file6, setFile6] = useState("");

  const [f23, setF23] = useState("");
  const [f23name, setF23Name] = useState(null);
  const [pddName, setPddName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setErName] = useState(null);
  const [er, setEr] = useState("");
  const [mrName, setMrName] = useState(null);
  const [mr, setMr] = useState("");
  const [cpaName, setcpaName] = useState(null);
  const [cpa, setcpa] = useState("");
  const [lod, setlod] = useState("");
  const [lodName, setlodName] = useState(null);
  const [irrName, setirrName] = useState(null);
  const [irr, setIrr] = useState("");
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");
  const [scope, setScope] = useState("");

  const [loading, setLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const fetchClientFindingData = async () => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${id}`,
        API_HEADER
      );
      const { record } = response.data;

      record.evidences.map((item, i) => {
        setFiles(item.name);
      });
      setFiles(record.evidences);
      setFindingFile(record.finding_document);

      setFormData({
        evidence_name: record.evidences || [],
        files: record.evidences,
        tl_remarks: record.tl_remarks,
        code: record.status,
        finding_status: record.finding_status,
      });

      setFindingStatus(record.finding_status);
      setRowCount(
        record.evidences
          ? record.evidences.length == 0
            ? 1
            : record.evidences.length
          : 1
      );
      setScope(record.scope_name.sector_name);
      setClientRemark(
        record.finding_status == 4
          ? (record.client_finding_remarks = "")
          : record.client_finding_remarks
      );
      setTLRemark(record.draft_tl_remarks);
      setATRemark(record.draft_at_remarks);
      setFindingFileName(record.client_finding_document);

      const dynamicUrl = record.evidences.map(
        (evidence) => `${BASE_DOCUMENT}/projects/${record.id}/${evidence.name}`
      );

      setDynamicName(dynamicUrl);

      const dName = record.evidences.map((evidence) => evidence.name);
      setDynamic(dName);

      let findingUrlTl = `${BASE_DOCUMENT}/projects/${record.id}/${record.finding_document}`;
      setFindingTlName(findingUrlTl);
      setFindingTl(record.finding_document);

      let findingUrlClient = `${BASE_DOCUMENT}/projects/${record.id}/${record.client_finding_document}`;
      setFindingClientName(findingUrlClient);
      setFindingClient(record.client_finding_document);

      let url0 = `${BASE_DOCUMENT}/projects/${record.id}/${record.lod_documents}`;
      setlodName(url0);
      setlod(record.lod_documents);

      let url3 = `${BASE_DOCUMENT}/documents/${
        record.earthood_id.split(" ")[0]
      }/${record.f23_doc}`;
      setF23Name(url3);
      setF23(record.f23_doc);

      let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.pdd_documents}`;
      setPddName(url4);
      setPdd(record.pdd_documents);

      let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.er_documents}`;
      setErName(url5);
      setEr(record.er_documents);

      let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.mr_documents}`;
      setMrName(url6);
      setMr(record.mr_documents);

      let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.irr_documents}`;
      setirrName(url7);
      setIrr(record.irr_documents);

      let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.cpa_documents}`;
      setcpaName(url8);
      setcpa(record.cpa_documents);

      let url9 = `${BASE_DOCUMENT}/projects/${record.id}/${record.other_documents}`;
      setOtherName(url9);
      setOther(record.other_documents);
    } catch (error) {}
  };

  useEffect(() => {
    fetchClientFindingData();
  }, []);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercent(percentCompleted);
    },
  };

  const addRow = () => {
    setRowCount((prevCount) => prevCount + 1);
  };

  const deleteRow = (index) => {
    setFormData((prevFormData) => {
      const newEvidenceName = [...prevFormData.evidence_name];
      newEvidenceName.splice(index, 1);
      return { ...prevFormData, evidence_name: newEvidenceName };
    });

    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
    setRowCount((prevCount) => prevCount - 1);
  };

  const handleMultiSizeError = (index) => {
    setFileErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = true;
      return newErrors;
    });
  };

  const handleFile = (file, index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = { ...file, index };
      return newFiles;
    });

    // Clear error if file is within size limit
    if (file.size <= dynamicMaxSize) {
      setFileErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = false;
        return newErrors;
      });
    } else {
      setFileErrors([]);
    }
  };

  const handleEvidenceChange = (index, value) => {
    setFormData((prevFormData) => {
      const newEvidenceName = [...prevFormData.evidence_name];
      newEvidenceName[index] = { ...newEvidenceName[index], remarks: value };
      return { ...prevFormData, evidence_name: newEvidenceName };
    });
  };

  const handleFindingRemarksChange = (content) => {
    setClientRemark(content);
  };

  const handleSubmitClientFindings = async (event) => {
    event.preventDefault();
    // setFormSubmitted(true);
    setIsSubmitting(false);

    if (
      clientRemark == null ||
      clientRemark == "<p><br></p>" ||
      clientRemark == "" ||
      FindingfileName == null
    ) {
      toast.error("Please upload the document and remarks. ");
    } else {
      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formDataToSend = new FormData();

        formDataToSend.append("project_id", id);

        formDataToSend.append("pdd_doc", file3);
        formDataToSend.append("er_doc", file1);
        formDataToSend.append("mr_doc", file2);
        formDataToSend.append("cpa_doc", file4);
        formDataToSend.append("irr_doc", file5);
        formDataToSend.append("other_documents", file6);

        formData.evidence_name.forEach((evidence, index) => {
          formDataToSend.append(
            `evidences[${index}][evidence_name]`,
            evidence.remarks
          );
        });

        files.forEach((file, index) => {
          formDataToSend.append(`evidences[${index}][evidence_file]`, file[0]);
        });

        formDataToSend.append("client_finding_document", FindingfileName);
        // formDataToSend.append("client_remarks", clientRemark)
        formDataToSend.append("finding_status", formData.finding_status);
        formDataToSend.append("client_finding_remarks", clientRemark);

        const response = await axios.post(
          `${client_finding_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (
          filePDDSizeError ||
          fileERSizeError ||
          fileMRSizeError ||
          fileCPASizeError ||
          fileIRRSizeError ||
          fileOtherSizeError
        ) {
          toast.error(`File size must below ${dynamicMaxSize} mb`);
        } else {
          toast.success("Document Submitted Successfully");

          setFiles([]);
          setEvidenceList([]);
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      {findingStatus === 4 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

          <form
            onSubmit={handleSubmitClientFindings}
            method="post"
            encType="multipart/form-data"
          >
            <div>
              <table className="table table-bordered table-hover table-responsive-sm">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Upload Document
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Uploaded Document
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* {
                    scope == 'VAL' ?
                      <td className="text-center lightgreen p-3">
                        PDD Document
                        <span style={{ color: "red" }}>*</span>
                      </td>
                      :

                      <td className="text-center lightgreen p-3">
                        PDD Document
                      </td>

                  } */}

                    <td className="text-center lightgreen p-3">
                      PDD Document
                      {scope == "VAL" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </td>

                    {/* {
                    scope == 'VAL' ?
                   
                      <FileUploaderComponent file={file3} setFile={setFile3} />


                      :
                  

                      <FileUploaderComponent file={file3} setFile={setFile3} />

                  } */}

                    <FileUploaderComponent file={file3} setFile={setFile3} />

                    <td>
                      <a target="_blank" href={pddName}>
                        {pdd}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center lightgreen p-3">
                      ER Document
                      {scope == "VER" || scope == "VAL" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </td>

                    <FileUploaderComponent file={file1} setFile={setFile1} />

                    <td>
                      <a target="_blank" href={erName}>
                        {er}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    {/* {
                    scope == "VER" ?
                      <td className="text-center lightgreen p-3">
                        MR Document
                        <span style={{ color: "red" }}>*</span>
                      </td>
                      :
                      <td className="text-center lightgreen p-3">
                        MR Document

                      </td>

                  } */}

                    <td className="text-center lightgreen p-3">
                      MR Document
                      {scope == "VER" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </td>

                    {/* {
                    scope == "VER" ?
                     
                      <FileUploaderComponent file={file2} setFile={setFile2} />

                      :
                     
                      <FileUploaderComponent file={file2} setFile={setFile2} />

                      
                  } */}

                    <FileUploaderComponent file={file2} setFile={setFile2} />

                    <td>
                      <a target="_blank" href={mrName}>
                        {mr}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center lightgreen p-3">
                      CPA Document
                      <span style={{ color: "red" }}></span>
                    </td>

                    <FileUploaderComponent file={file4} setFile={setFile4} />

                    <td>
                      <a target="_blank" href={cpaName}>
                        {cpa}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center lightgreen p-3">
                      IRR Document
                      <span style={{ color: "red" }}></span>
                    </td>

                    <FileUploaderComponent file={file5} setFile={setFile5} />

                    <td>
                      <a target="_blank" href={irrName}>
                        {irr}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center lightgreen p-3">
                      Other Document
                    </td>

                    <FileUploaderComponent file={file6} setFile={setFile6} />

                    <td>
                      <a target="_blank" href={otherName}>
                        {other}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered table-hover table-responsive-sm">
                <thead>
                  <tr>
                    <th className="form-label lightgreen fs-6 text-center table-heading-color">
                      S.No.
                    </th>

                    <th className="form-label lightgreen fs-6 text-center table-heading-color">
                      Evidence Name
                    </th>

                    <th className="form-label lightgreen fs-6 text-center table-heading-color">
                      Upload File
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Uploaded Document
                    </th>

                    <th className="table-heading-color">
                      <PlusOutlined onClick={addRow} />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {[...Array(rowCount)].map((_, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="text"
                          name="evidence_name"
                          className="form-control"
                          // disabled={formdisabled}
                          placeholder="evidence name"
                          value={
                            formData.evidence_name[index]
                              ? formData.evidence_name[index].remarks
                              : ""
                          }
                          onChange={(event) =>
                            handleEvidenceChange(index, event.target.value)
                          }
                        />
                      </td>

                      <td>
                        <FileUploader
                          handleChange={(file) => handleFile(file, index)}
                          name="f23_doc"
                          multiple={true}
                          maxSize={dynamicMaxSize}
                          types={fileTypes}
                          onSizeError={() => handleMultiSizeError(index)}
                        />

                        {fileErrors[index] ? (
                          <span className="text-danger">
                            File size greater than {dynamicMaxSize} mb is not
                            allowed
                          </span>
                        ) : (
                          <span>
                            {files[index] && files[index][0]?.name && (
                              <span>{`File name: ${files[index][0]?.name}`}</span>
                            )}
                          </span>
                        )}
                        {/* </span>
                                    )} */}
                      </td>

                      {formData.evidence_name[index]?.name ? (
                        <>
                          <td>
                            <a target="_blank" href={dynamicname[index]}>
                              {dynamic[index]}
                            </a>
                          </td>
                        </>
                      ) : (
                        <td></td>
                      )}

                      <td>
                        <CloseOutlined onClick={() => deleteRow(index)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="row">
                <div className="col-6 my-4">
                  <div>
                    <label
                      className="form-label lightgreen fs-6"
                      htmlFor="remarks"
                    >
                      Upload Response to Findings{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="borderfileupload">
                      <FileUploaderComponent
                        file={FindingfileName}
                        setFile={setFindingFileName}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6" style={{ marginTop: "25px" }}>
                  <p className="form-label lightgreen text-bold fs-6">
                    Finding Document
                  </p>
                  <span>
                    <a target="_blank" href={findingTlName}>
                      {findingTl}
                    </a>
                  </span>
                </div>

                {findingClient == null ? (
                  ""
                ) : (
                  <div className="row">
                    <div className="col-6" style={{ marginBottom: "25px" }}>
                      <p className="form-label lightgreen text-bold fs-6">
                        Client Finding Document
                      </p>
                      <span>
                        <a target="_blank" href={findingClientName}>
                          {findingClient}
                        </a>
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className="row mb-3 mt-3 ">
                <div className="col-12 ">
                  <label className="form-label lightgreen fs-6 mb-2">
                    Client Remarks
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={clientRemark}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: clientRemark }}
                    onChange={handleFindingRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              {tlremark && (
                <div className="row  ">
                  <div className="col-12">
                    <label className="form-label lightgreen fs-6 mb-2">
                      Team Leader Remarks
                    </label>

                    <ReactQuill
                      theme="snow"
                      value={tlremark}
                      name="tlremarks"
                      required
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: tlremark }}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}

              {atremark && (
                <div className="row  ">
                  <div className="col-12">
                    <label className="form-label lightgreen fs-6 mb-2">
                      Team Member Remarks
                    </label>

                    <ReactQuill
                      theme="snow"
                      value={atremark}
                      name="tlremarks"
                      required
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: atremark }}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="container">
              <div className="row">
                <div className="d-flex justify-content-end my-3 col-12 pr-0">
                  <button
                    className="btn fs-5"
                    style={{
                      backgroundColor: "#07b6af",
                      color: "white",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <form onSubmit={handleSubmitClientFindings} method="post">
          <div>
            <table className="table table-bordered table-hover table-responsive-sm">
              <thead>
                <tr>
                  <th
                    className="text-center lightgreen p-3 fw-bolder fs-6"
                    style={{ background: "#ABEBC6" }}
                  >
                    Document Name
                  </th>

                  <th
                    className="text-center lightgreen p-3 fw-bolder fs-6"
                    style={{ background: "#ABEBC6" }}
                  >
                    Uploaded Document
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center lightgreen p-3">
                    PDD Document
                    {scope == "VAL" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                  </td>

                  <td>
                    <a target="_blank" href={pddName}>
                      {pdd}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td className="text-center lightgreen p-3">
                    ER Document
                    {scope == "VER" || scope == "VAL" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                  </td>

                  <td>
                    <a target="_blank" href={erName}>
                      {er}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="text-center lightgreen p-3">
                    MR Document
                    {scope == "VER" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                  </td>

                  <td>
                    <a target="_blank" href={mrName}>
                      {mr}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td className="text-center lightgreen p-3">
                    CPA Document
                    <span style={{ color: "red" }}></span>
                  </td>

                  <td>
                    <a target="_blank" href={cpaName}>
                      {cpa}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td className="text-center lightgreen p-3">
                    IRR Document
                    <span style={{ color: "red" }}></span>
                  </td>

                  <td>
                    <a target="_blank" href={irrName}>
                      {irr}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td className="text-center lightgreen p-3">
                    Other Document
                    <span style={{ color: "red" }}></span>
                  </td>

                  <td>
                    <a target="_blank" href={otherName}>
                      {other}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-bordered table-hover table-responsive-sm">
              <thead>
                <tr>
                  <th className="form-label lightgreen fs-6 text-center table-heading-color">
                    S.No.
                  </th>

                  <th className="form-label lightgreen fs-6 text-center  table-heading-color">
                    Evidence Name
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                    Uploaded Document
                  </th>
                </tr>
              </thead>

              <tbody>
                {formData.evidence_name &&
                  formData.evidence_name.map((_, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="text"
                          name="evidence_name"
                          className="form-control"
                          disabled
                          placeholder="evidence name"
                          value={
                            formData.evidence_name[index]
                              ? formData.evidence_name[index].remarks
                              : ""
                          }
                          onChange={(event) =>
                            handleEvidenceChange(index, event.target.value)
                          }
                        />
                      </td>

                      {formData.evidence_name[index]?.name ? (
                        <>
                          <td>
                            <a target="_blank" href={dynamicname[index]}>
                              {dynamic[index]}
                            </a>
                          </td>
                        </>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="row">
              {(findingStatus == 2 && findingClient == null) ||
              (findingStatus == 1 && findingClient == null) ? (
                ""
              ) : (
                <div className="col-6" style={{ marginTop: "25px" }}>
                  <p className="form-label lightgreen text-bold fs-6">
                    Finding Document
                  </p>
                  <span>
                    <a target="_blank" href={findingTlName}>
                      {findingTl}
                    </a>
                  </span>
                </div>
              )}

              {(findingStatus == 2 && findingClient == null) ||
              (findingStatus == 1 && findingClient == null) ? (
                ""
              ) : (
                <div className="col-6" style={{ marginTop: "25px" }}>
                  <p className="form-label lightgreen text-bold fs-6">
                    Client Finding Document
                  </p>
                  <span>
                    <a target="_blank" href={findingClientName}>
                      {findingClient}
                    </a>
                  </span>
                </div>
              )}
            </div>

            {(findingStatus == 2 && clientRemark == null) ||
            (findingStatus == 1 && clientRemark == null) ? (
              ""
            ) : (
              <div className="row mt-4 mb-4">
                <div className="col-12">
                  <label className="form-label lightgreen fs-6 mb-3">
                    Client Remarks<span style={{ color: "red" }}>*</span>
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={clientRemark}
                    name="remarks"
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}

            {tlremark &&
              (findingStatus == 2 || findingStatus == 1 ? (
                ""
              ) : (
                <div className="row mt-4">
                  <div className="col-12">
                    <label className="form-label lightgreen fs-6 mb-3">
                      Team Leader Remarks
                    </label>

                    <ReactQuill
                      theme="snow"
                      value={tlremark}
                      name="tlremarks"
                      readOnly={true}
                      dangerouslySetInnerHTML={{ __html: tlremark }}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              ))}

            {atremark && (
              <div className="row  ">
                <div className="col-12">
                  <label className="form-label lightgreen fs-6 mb-2">
                    Team Member Remarks
                  </label>

                  <ReactQuill
                    theme="snow"
                    value={atremark}
                    name="tlremarks"
                    required
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: atremark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default ClientDraftFinding;
