import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logoicon1.png";
import { ReactComponent as DashboardIcon } from '../../src/assets/tachometer-alt-solid.svg'
import { ReactComponent as AllClientIcon } from '../../src/assets/users-solid.svg'
import { ReactComponent as FileCirclePLusIcon } from '../../src/assets/file-circle-plus-solid.svg'
import { ReactComponent as FileLinesIcon } from '../../src/assets/file-lines-solid.svg'
import { ReactComponent as FileCircleCheckIcon } from '../../src/assets/file-circle-check-solid.svg'
import { ReactComponent as DashboardIcon1 } from '../../src/assets/dashboard1.svg'
import { ReactComponent as DashboardIcon2 } from '../../src/assets/dashboard2.svg'
import { ReactComponent as ReportIcon } from '../../src/assets/report.svg'
import { ReactComponent as ProposalIcon } from '../../src/assets/proposal.svg'
import { ReactComponent as ClientIcon } from '../../src/assets/clients.svg'
import { ReactComponent as AllProjectIcon } from '../../src/assets/allprojects.svg'
import { ReactComponent as TAIcon } from '../../src/assets/taexpert.svg'
import { ReactComponent as AddProposalIcon } from '../../src/assets/addproposal.svg'
import { ReactComponent as OngoingIcon } from '../../src/assets/ongoing.svg'
import { ReactComponent as CompletedIcon } from '../../src/assets/completed.svg'

const SideNavbar = () => {
  
  let [username, SetUserName] = useState("");
  let [multiUser, setMultiUser] = useState(0);

  useEffect(function () {
    const name = sessionStorage.getItem("name");
    const multi_user = sessionStorage.getItem("multi_user");
    SetUserName(name);
    setMultiUser(multi_user);
  }, []);

  const navigate = useNavigate();

  const designation_id = sessionStorage.getItem("designation_id");
  const designation_name = sessionStorage.getItem("designation");


  const logout = () => {
    sessionStorage.clear();
    {
      designation_id == 13 ? navigate("/clientlogin") : navigate("/login");
    }
  };
  return (
    <>
    
      <aside className="main-sidebar sidenavbar_bg elevation-1 mb-3">
        <Link to="/dashboard" className="brand-link text-decoration-none">
          <img
            src={logo}
            alt="Logo"
            className="brand-image  elevation-1"
            // style={{ width:60,height:40}}
          />
          <span className="brand-text font-weight-bold text-black">
            EarthLink
          </span>
        </Link>

        <div className="sidebar">
          <nav className="mt-2">

          {
            multiUser==0?(
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              
              <li className="nav-item btnhovergrey mt-3">

                <Link
                  className={`nav-link text-black ${
                    window.location.pathname === "/dashboard" ? "bg-cyan" : ""
                  }`}
                  to="/dashboard"
                  style={{display: "flex", alignItems: "center"}}
                >
                  <DashboardIcon style={{width:'25px',height:'25px', marginRight: "5px"}}/>
                  <p > Dashboard</p>
                </Link>
              </li>
              {designation_id == 6 ? (
                <>
                  <li className="nav-item  btnhovergrey mt-3">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/projects"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/projects"
                      style={{display: "flex", alignItems: "center"}}
                    >
                      
                      <AddProposalIcon  style={{width:'25px',height:'25px', marginRight: "5px"}}/>
                      <p> Add Proposal</p>
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}
              {(designation_id != 13 && designation_id != 14) ? (
                <>
                  {designation_id != 8 && designation_id != 9 && designation_id != 7? (
                    <li className="nav-item mt-3 btnhovergrey">
                      <Link
                        className={`nav-link text-black ${
                          window.location.pathname === "/allprojects"
                            ? "bg-cyan"
                            : ""
                        }`}
                        to="/allprojects"
                        style={{display: "flex", alignItems: "center"}}
                      >
                       
                        <ProposalIcon  style={{width:'25px',height:'25px', marginRight: "5px"}}/>
                        <p>All Proposals</p>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/clients" ? "bg-cyan" : ""
                      }`}
                      to="/clients"
                      style={{display: "flex", alignItems: "center"}}
                    >
                      <ClientIcon  style={{width:'25px',height:'25px', marginRight: "5px"}}/>
                      <p>All Clients</p>
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}
              {designation_id == 13 ? (
                <>
                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/ongoingprojects"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/ongoingprojects"
                      style={{display: "flex", alignItems: "center"}}
                    >
                      
                      <OngoingIcon   style={{width:'25px',height:'25px', marginRight: "5px"}}/>
                      <p>Ongoing Projects</p>
                    </Link>
                  </li>
                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/completedprojects"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/completedprojects"
                      style={{display: "flex", alignItems: "center"}}
                    >
                     
                      <CompletedIcon  style={{width:'25px',height:'25px', marginRight: "5px"}}/>
                      <p>Completed Projects</p>
                    </Link>
                  </li>
                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/clientdashboardgraph"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/clientdashboardgraph"
                      style={{display: "flex", alignItems: "center"}}
                    >
                      
                      <DashboardIcon1 style={{width:'25px',height:'25px', marginRight: "5px"}}/>
                      <p>Project Insights</p>
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}


             { designation_id == 2 || designation_id == 8 || designation_id == 9 ||  designation_id == 14 ? (
                <>
                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/totalprojectsat"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/totalprojectsat"
                      style={{display: "flex", alignItems: "center"}}
                    >
                      
                      <AllProjectIcon   style={{width:'25px',height:'25px', marginRight: "5px"}}/>

                      <p>All Projects</p>
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}

{designation_id == 2 || designation_id == 5? (
                <>
                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/maindashboard"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/maindashboard"
                      style={{display: "flex", alignItems: "center"}}
                    >
                    
                       <DashboardIcon2   style={{width:'25px',height:'25px', marginRight: "5px"}}/>
                      <p>Sales Insights</p>
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}

{designation_id == 2 || designation_id == 9  ? (
                <>
                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/projectdashboard"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/projectdashboard"
                      style={{display: "flex", alignItems: "center"}}
                    >
                      
                      <DashboardIcon1 style={{width:'25px',height:'25px', marginRight: "5px"}}/>

                      <p>Project Insights</p>
                    </Link>
                  </li>

                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/reports/projectreport"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/reports/projectreport"
                      style={{display: "flex", alignItems: "center"}}
                    >
                      <ReportIcon   style={{width:'25px',height:'25px', marginRight: "5px"}}/>
                      <p>Project Report</p>
                    </Link>
                  </li>
                  
                </>
              ) : (
                ""
              )}

{designation_id == 8 ? (
                <>
                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/taexpertprojects"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/taexpertprojects"
                      style={{display: "flex", alignItems: "center"}}
                    >
                      
                      <TAIcon style={{width:'25px',height:'25px', marginRight: "5px"}}/>

                      <p>Projects as TA Expert</p>
                    </Link>
                  </li>

                  <li className="nav-item mt-3 btnhovergrey">
                    <Link
                      className={`nav-link text-black ${
                        window.location.pathname === "/atdashboardgraph"
                          ? "bg-cyan"
                          : ""
                      }`}
                      to="/atdashboardgraph"
                      style={{display: "flex", alignItems: "center"}}
                    >
                      
                      <DashboardIcon1   style={{width:'25px',height:'25px', marginRight: "5px"}}/>

                      <p>Project Insights</p>
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}
        
            </ul>
            ):(
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li className="nav-item btnhovergrey mt-3">
                  <Link
                    className={`nav-link text-black ${
                      window.location.pathname === "/dashboard" ? "bg-cyan" : ""
                    }`}
                    to="/dashboard"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DashboardIcon style={{ width: "25px", height: "25px", marginRight: "5px" }} />
                    <p>Dashboard</p>
                  </Link>
                </li>
              </ul>
            )
          }

          </nav>
        </div>
      </aside>
    </>
  );
};

export default SideNavbar;
