import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FileUploader } from "react-drag-drop-files";
import { BASE_DOCUMENT, Registrar_kickoutAction_url, KickoutDocuments_url, get_kickout_url, get_KickoutInfo_url, Registrar_RFI_kickoutAction_url } from "../../config";
import { get_project_details_url } from "../../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../../config";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import FileUploaderComponent from "../../utlis/FileUploaderComponent";
import LoaderComponent from "../../utlis/LoderComponent";

const fileTypes = [
    "JPG",
    "JPEG",
    "PDF",
    "RAR",
    "XLS",
    "XLSX",
    "DOC",
    "DOCX",
    "ZIP",
    "XLSM",
];


const KickoutFinalAction = () => {


    const { id } = useParams();
    const designation_id = sessionStorage.getItem("designation_id");
    const userdata = JSON.parse(sessionStorage.getItem("user"));


    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size



    const [kickoutStatus, setkickoutStatus] = useState("");
    const [action, setAction] = useState("");
    const [rowCount, setRowCount] = useState(1);
    const [remarkTL, setRemarkTL] = useState("");
    const [remarkSbu, setRemarkSbu] = useState("");
    const [remarkRegistrar, setRemarkRegistrar] = useState("");
    const [dynamicname, setDynamicName] = useState([]);
    const [dynamic, setDynamic] = useState([]);
    const [multifileSizeError, setMultiFileSizeError] = useState(false);
    const [files, setFiles] = useState([]);
    const [docsName, setDocsName] = useState([])
    const [role, setRole] = useState("");
    const [isTL, setIsTL] = useState(false);
    const [isAT, setIsAT] = useState(false);

    const [file1, setFile1] = useState('');
    const [kickOutDocName, setKickOutDocName] = useState(null)
    const [kickOutDoc, setKickOutDoc] = useState("")
    const [fileKickOutDocSizeError, setFileKickOutDocSizeError] = useState(false);
    const [kickoutId, setKickoutId] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);


    // const [formData, setFormData] = useState({
    //   evidence_name: [],
    //   project_status: "",
    // });

    // const fetchDocs = async (ids) => {
    //   try {
    //     let payload = {
    //       project_id: ids,
    //     };
    //     const response = await axios.post(
    //       `${KickoutDocuments_url}`,
    //       payload,
    //       API_HEADER
    //     );

    //     const { record, documents } = response.data;

    //     setRowCount(
    //       documents
    //         ? documents.length == 0
    //           ? 1
    //           : documents.length
    //         : 1
    //     );



    //     documents.map((item, i) => {

    //       setFiles(prevFilesName => [...prevFilesName, item.document_name]);       
    //       setDocsName(prevDocsName => [...prevDocsName, item.doc_desc]); 

    //     });


    //     const dynamicUrl = documents.map(

    //       (kickout,i) => 
    //       `${BASE_DOCUMENT}/projects/${id}/${kickout.document_name}`
    //     );
    //     setDynamicName(dynamicUrl);

    //     const dName = documents.map((kickout) => kickout.document_name);
    //     setDynamic(dName);
    //   } catch (error) {}
    // };

    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${get_KickoutInfo_url}/${ids}`,
                API_HEADER
            );
            const { kickout } = response.data;
            const { record } = response.data;
            const { teams } = response.data;

            setRemarkRegistrar(record.kickout_status == 32 ? record.kick_registrar_remarks = '' : record.kick_registrar_remarks);
            setkickoutStatus(record.kickout_status);

            setRemarkTL(record.tl_remarks);
            setRemarkSbu(record.sbu_remarks);

            setKickoutId(kickout.id)




            let url1 = `${BASE_DOCUMENT}/projects/${kickout.id}/${kickout.kickout_document}`;
            setKickOutDocName(url1);
            setKickOutDoc(kickout.kickout_document);


            const isTeamLeader = record.team.some((item) => item.role_id == 1);
            setIsTL(isTeamLeader);

            const isTeamMember = record.team.some((item) => item.role_id > 1);
            setIsAT(isTeamMember);

            if (designation_id == 8) {
                if (isTeamLeader) {
                    setRole("Team Leader");
                } else {
                    setRole("Team Member");
                }
            }

        } catch (error) { }
    };

    useEffect(() => {
        fetchData(id);
        // fetchDocs(id);
    }, [id]);

    const CONFIG_Token2 = {
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercent(percentCompleted);
        }
    };

    const handleRemarksChange = (content) => {
        setRemarkRegistrar(content);
    };

    const handleActionChange = (e) => {
        setAction(e.target.value);
    };

    console.log("kickoutId",kickoutId)

    const navigate = useNavigate();

    const handleSubmitAction = async (e) => {
        e.preventDefault();
        setIsSubmitting(false);


        if (remarkRegistrar == null || remarkRegistrar == '<p><br></p>' || remarkRegistrar == '' || action == '') {
            toast.error('Please Add Status or Some Remarks')
        }
        else {
            try {
                setIsSubmitting(true);

                const formDataToSend = new FormData();


                formDataToSend.append("project_id", id);
                formDataToSend.append("kickout_document", file1);
                formDataToSend.append("kick_registrar_remarks", remarkRegistrar);
                formDataToSend.append("kickout_id", kickoutId);
                formDataToSend.append("project_status", action);

                const response = await axios.post(
                    `${Registrar_RFI_kickoutAction_url}`,
                    formDataToSend,
                    CONFIG_Token2
                );
                if (!response.data.status) {
                    toast.error(response.data.message);
                } else {
                    toast.success("Document Submitted Successfully");
                    navigate("/dashboard");
                    setRemarkRegistrar("");
                    setAction("");

                }
            } catch (error) {
                toast.error(error);
            }
            finally {
                // setLoading(false); // Hide loader
                setIsSubmitting(false);

            }
        }
    };


    const handleFileKickOutDocChange = (file1) => {
        if (file1.size <= dynamicMaxSize * 1024 * 1024) {
            setFileKickOutDocSizeError(false);
        }

        setFileKickOutDocSizeError(false);
        setFile1(file1);
    };
    const handleKickOutDocSizeError = (file1) => {
        setFileKickOutDocSizeError(true);
    };



    return (
        <>
            <LoaderComponent isSubmitting={isSubmitting} percent={percent} />


            <form method="post" encType="multipart/form-data" onSubmit={handleSubmitAction}>
                {kickoutStatus === 32 ? (
                    <div className="col-12 border-0 mx-auto">
                        <p className="lightgreen fw-bold my-3 fs-6">Remarks<span style={{ color: "red" }}>*</span>
                        </p>
                        <hr className="mb-3 lightgreen" />
                        <div className="row mt-4">
                            <span className="col-10 col-lg-4 col-md-4">
                                <input
                                    type="radio"
                                    id="kickout"
                                    name="kickout"
                                    value={11}
                                    onChange={handleActionChange}
                                    className="text-danger"
                                />
                                <label className="text-danger mx-1" htmlFor="kickout">
                                    Kick Out
                                </label>
                            </span>

                            <span className="col-10 col-lg-4 col-md-4">
                                <input
                                    type="radio"
                                    id="kickout"
                                    name="kickout"
                                    value={12}
                                    onChange={handleActionChange}
                                    className="text-success"
                                />
                                <label
                                    className="text-success mx-1"
                                    htmlFor="issued"
                                >
                                    Issued/Registered
                                </label>
                            </span>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <ReactQuill
                                    theme="snow"
                                    value={remarkRegistrar}
                                    name="remarks"
                                    dangerouslySetInnerHTML={{ __html: remarkRegistrar }}
                                    onChange={handleRemarksChange}
                                    style={{

                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>

                        <table className="table table-bordered  table-hover mt-4">
                            <thead>
                                <tr>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                        S.No
                                    </th>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                        Document Name
                                    </th>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                                        Upload Document
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">1</td>

                                    <td className="text-center lightgreen p-3">
                                        Kick Out Document
                                    </td>

                                    {/* <td className='p-3'>

                                    <FileUploader
                                        handleChange={handleFileKickOutDocChange}
                                        name="kickout_doc"
                                        types={fileTypes}
                                        multiple={false}
                                        maxSize={dynamicMaxSize}
                                        required={false}
                                        onSizeError={handleKickOutDocSizeError}
                                    />

                                    {fileKickOutDocSizeError ? (
                                        <span className="text-danger">
                                            File size greater than {dynamicMaxSize}MB is not allowed
                                        </span>
                                    ) : (

                                        <span>{file1 ? file1.name : ""}</span>
                                    )}
                                </td> */}

                                    <FileUploaderComponent file={file1} setFile={setFile1} />
                                </tr>
                            </tbody>
                        </table>

                        <div className="container my-4 d-flex justify-content-end pr-0">
                            <button
                                className="btn my-4 px-3 fs-5"
                                style={{
                                    backgroundColor: "#07b6af",
                                    color: "white",
                                }}
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                ) : (
                    (
                        <div className="col-12 border-0 mx-auto">
                            <p className="lightgreen fw-bold my-3 fs-6">Remarks</p>
                            <hr className="mb-3 lightgreen" />
                            <div className="row mt-4">
                                <div className="col-12">
                                    <ReactQuill
                                        theme="snow"
                                        value={remarkRegistrar}
                                        name="remarks"
                                        readOnly={true}
                                        dangerouslySetInnerHTML={{ __html: remarkRegistrar }}

                                        style={{

                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>
                            </div>

                            <table className="table table-bordered  table-hover mt-4">
                                <thead>
                                    <tr>
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                            S.No
                                        </th>
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                            Document Name
                                        </th>

                                        <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                                            Uploaded Document
                                        </th>

                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td class="text-center">1</td>

                                        <td className="text-center lightgreen p-3">
                                            KickOut Document
                                        </td>
                                        <td>
                                            <a href={kickOutDocName} target="_blank">{kickOutDoc}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    )



                )


                }
            </form>
        </>

    );
};

export default KickoutFinalAction;
