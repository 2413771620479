import { Input, Table, Tabs, DatePicker, Button, Select, Spin } from "antd";
import { Tooltip as Tip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCircleQuestion,
  faFileCircleCheck,
  faFileArrowDown,
  faFileSignature,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  API_HEADER,
  getDashboardData,
  at_tl_data_url,
  sbu_data_url,
  getCountryList,
  get_client_name_url,
  get_scope_url,
  get_program_url,
  get_kickout_url,
  team_change_listing_url,
} from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import LegendTable from "../LegendTable";
import ProjectNameWithDelay from "../ProjectNameWithDelay";

const { Option } = Select;

const SBUDash = () => {
  const navigate = useNavigate();

  let [teamChangeLoader, SetTeamChangeLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [siteVisitRequest, setSiteVisitRequest] = useState(0);
  const [underClarification, setUnderClarification] = useState(0);
  const [siteVisitApproved, setSiteVisitApproved] = useState(0);
  const [ccReviewRequest, setCCReviewRequest] = useState(0);
  const [rfiCompleted, setRFICompleted] = useState(0);
  const [kickoutCC, setkickoutCC] = useState(0);
  const [teamChange, setTeamChange] = useState(0);
  const [projectStatuskey, setProjectStatuskey] = useState(9);
  const [spinloader, setspinloader] = useState(true);
  const [teamChangeStatus, setTeamChangeStatus] = useState("");

  let [loader, Setloader] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const getDashData = async () => {
    try {
      const result = await axios.get(`${getDashboardData}`, API_HEADER);
      const dashboard = result.data.dashboard;

      setSiteVisitRequest(dashboard.status6);
      setUnderClarification(dashboard.status7);
      setSiteVisitApproved(dashboard.status8);
      setCCReviewRequest(result.data.underCCReview);
      setRFICompleted(dashboard.kickout_request);
      setkickoutCC(result.data.kickoutCC);
      setTotalProject(dashboard.total_project);
      setclientPendingDoc(dashboard.pending_at_client);
      setearthoodPendingDoc(dashboard.pending_at_earthood);
      setTeamChange(dashboard.team_change);

      setspinloader(false);
    } catch (error) {}
  };

  useEffect(() => {
    getDashData();
  }, []);

  const [statuskey, setStatus] = useState(10);
  const [kickoutStatus, setKickoutStatus] = useState("");
  const [alldata, setAlldata] = useState([]);
  const [teamChangedata, setTeamChangedata] = useState([]);
  const [projectstatus, setProjectStatus] = useState("");
  const [kickoutlist, setkickoutList] = useState([]);
  const [type, setType] = useState(1);
  const [activeKey, setActiveKey] = useState("1");
  const [totalProject, setTotalProject] = useState(0);
  const [clientPendingDoc, setclientPendingDoc] = useState(0);
  const [earthoodPendingDoc, setearthoodPendingDoc] = useState(0);

  const allData = async () => {
    try {
      let payload = {
        site_visit_status: statuskey,
        page: pagination.current,
        limit: pagination.pageSize,
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
        country: country ? country : null,
        client_id: client_id ? client_id : null,
        program: program ? program : null,
        scope: scope ? scope : null,
        search: search ? search : null,
        type: type,
        // kickout_status: kickoutStatus,
        project_status: projectstatus,
      };
      const response = await axios.post(
        `${at_tl_data_url}`,
        payload,
        API_HEADER
      );
      setAlldata(response.data.records.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.records.total,
      }));

      Setloader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleTeamChange = async (id, teamId) => {
    navigate(`/teamchangemodule/${id}`, { state: { teamId } });
  };

  const allTeamChangeData = async () => {
    try {
      let payload = {
        search: search ? search : null,
        team_change_approved_status: teamChangeStatus,
      };
      const response = await axios.post(
        `${team_change_listing_url}`,
        payload,
        API_HEADER
      );

      setTeamChangedata(response.data.records.data);

      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response.data.records.total,
      }));

      SetTeamChangeLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleTabChange = (key) => {
    setFromDate(null);
    setToDate(null);
    setCountry(null);
    setClient_id(null);
    setProgram(null);
    setScope(null);
    setSearch(null);

    setActiveKey(key);

    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));

    setType(1);

    if (key == 1) {
      setStatus(10);
      Setloader(true);
      setProjectStatus("");
      setKickoutStatus("");
    } else if (key == 2) {
      setStatus(11);
      Setloader(true);
      setProjectStatus("");
      setKickoutStatus("");
    } else if (key == 3) {
      setStatus(12);
      Setloader(true);
      setProjectStatus("");
      setKickoutStatus("");
    } else if (key == 4) {
      Setloader(true);
      setType(2);
      setProjectStatus("");
      setKickoutStatus("");
    } else if (key == 5) {
      setStatus("");
      setType("");
      setProjectStatus(11);
      // setKickoutStatus(1);
      Setloader(true);
    } else if (key == 6) {
      setStatus("");
      setType("");
      Setloader(true);
      setProjectStatus(11);
      // setKickoutStatus(14);
    } else if (key == 7) {
      SetTeamChangeLoader(true);
    }
  };

  useEffect(() => {
    if (activeKey != 7) {
      allData();
    }
  }, [loader]);

  useEffect(() => {
    if (activeKey == 7) {
      allTeamChangeData();
    }
  }, [teamChangeLoader, teamChangeStatus]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    Setloader(true);
  };

  const dateFormat = "DD/MM/YYYY";
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [country, setCountry] = useState(null);
  const [client_id, setClient_id] = useState(null);
  const [program, setProgram] = useState(null);
  const [search, setSearch] = useState(null);

  const handleSearch = (value) => {
    setSearch(value);
    Setloader(true);
  };

  const editFormForDeskReview = async (id) => {
    navigate(`/atdeskreview/${id}`);
  };
  const editFormForSBUCCReview = async (id) => {
    navigate(`/atdeskreview/${id}`);
  };

  const editFormForSBUKickout = async (id) => {
    navigate(`/kickoutmodule/${id}`);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    Setloader(true);
  };
  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    Setloader(true);
  };

  const handleSearchByDateRange = (value) => {
    const currentDate = moment();
    if (fromDate && fromDate.isAfter(currentDate)) {
      toast.error("From date cannot be a future date");
    } else if (toDate && toDate.isAfter(currentDate)) {
      toast.error("To date cannot be a future date");
    } else if (fromDate && toDate && fromDate.isAfter(toDate)) {
      toast.error("From date cannot be greater than to date");
    } else {
      Setloader(true);
    }
  };
  const handleClientNameSearch = (value) => {
    setClient_id(value);
    Setloader(true);
  };
  const handleProgramNameSearch = (value) => {
    setProgram(value);
    Setloader(true);
  };
  const handleCountrySearch = (value) => {
    setCountry(value);
    Setloader(true);
  };
  const handleScopeSearch = (value) => {
    setScope(value);
    Setloader(true);
  };

  const handleSearchAll = (value) => {
    setSearch(value);
    Setloader(true);
  };

  const [scope, setScope] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [scopeList, setScopeList] = useState([]);
  const [clientname, setClientname] = useState([]);
  const [programname, setProgramname] = useState([]);

  const getCountry = async () => {
    try {
      const result = await axios.get(`${getCountryList}`);
      setCountryList(result.data.data);
    } catch (error) {}
  };
  const getClientname = async () => {
    try {
      const result = await axios.get(`${get_client_name_url}`);
      setClientname(result.data.data);
    } catch (error) {}
  };
  const getProgramname = async () => {
    try {
      const result = await axios.get(`${get_program_url}`);
      setProgramname(result.data.data);
    } catch (error) {}
  };
  const getScope = async () => {
    try {
      const result = await axios.get(`${get_scope_url}`);
      setScopeList(result.data.data);
    } catch (error) {}
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getCountry();
    getClientname();
    getProgramname();
    getScope();
  }, []);

  const handletotalprojects = () => {
    navigate("/totalprojectsat");
  };

  const handleClientEndPending = () => {
    navigate("/totalprojectsat", { state: { id: 1 } });
  };

  const handleEarthoodEndPending = () => {
    navigate("/totalprojectsat", { state: { id: 2 } });
  };

  const handleTeamChangeStatus = (e) => {
    setTeamChangeStatus(e.target.value);
  };

  const columnSiteVisitRequest = [
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
      defaultSortOrder: "ascend",
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          requested Date
        </span>
      ),
      width: 140,
      render: (text, record) => (
        <span className="text-capitalize     font14px">
          {record.created_at.slice(8, 10)}-{record.created_at.slice(5, 7)}-
          {record.created_at.slice(0, 4)}
        </span>
      ),
      sorter: (record1, record2) => {
        return record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)
          ? 1
          : record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          // <span className="    font14px">
          //     {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Client Name
        </span>
      ),
      width: 150,
      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Project Name
        </span>
      ),
      width: 160,
      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,
      render: (text, record) => (
        // <span className="text-capitalize     font14px textcolorgreen ">{record.scope_name?.scope}</span>
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => (
        // <span className="text-capitalize     font14px">{record.country_name?.description}</span>
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Action
        </span>
      ),
      key: "x",
      fixed: "right",
      width: 90,
      render: (text, record) => (
        <a className="">
          <EditOutlined
            onClick={() => editFormForDeskReview(record.id)}
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
          />
        </a>
      ),
    },
  ];

  const columnUnderClarification = [
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Received Date
        </span>
      ),
      width: 140,
      render: (text, record) => (
        <span className="text-capitalize     font14px">
          {record.created_at.slice(8, 10)}-{record.created_at.slice(5, 7)}-
          {record.created_at.slice(0, 4)}
        </span>
      ),
      sorter: (record1, record2) => {
        return record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)
          ? 1
          : record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
      // defaultSortOrder: 'ascend',
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Client Name
        </span>
      ),
      width: 120,
      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Project Name
        </span>
      ),
      width: 160,
      render: (text, record) => (
        // <span className="text-capitalize     font14px textcolor">{record.project_name}</span>
        <ProjectNameWithDelay record={record} />
      ),
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,
      render: (text, record) => (
        // <span className="text-capitalize  textcolorgreen font14px">{record.scope_name?.scope}</span>
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => (
        // <span className="text-capitalize     font14px textcolorgreen     font14px ">{record.program_name?.description}</span>
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 90,
      render: (text, record) => (
        <a className="">
          <EyeOutlined
            onClick={() => editFormForDeskReview(record.id)}
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
          />
        </a>
      ),
    },
  ];

  const columnSiteVisitApproved = [
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      width: 70,
      fixed: "left",
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Approved Date
        </span>
      ),
      width: 120,
      render: (text, record) => (
        <span className="text-capitalize     font14px">
          {record.created_at.slice(8, 10)}-{record.created_at.slice(5, 7)}-
          {record.created_at.slice(0, 4)}
        </span>
      ),
      sorter: (record1, record2) => {
        return record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)
          ? 1
          : record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          EID
        </span>
      ),
      width: 140,
      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Client Name
        </span>
      ),
      width: 100,
      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Project Name
        </span>
      ),
      width: 120,
      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Scope
        </span>
      ),
      width: 90,
      render: (text, record) => (
        // <span className="text-capitalize     font14px textcolorgreen">{record.scope_name?.scope}</span>
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => (
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",

      width: 90,
      render: (record) => (
        <EyeOutlined
          onClick={() => editFormForDeskReview(record.id)}
          style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
        />
      ),
    },
  ];

  const columnRFICompleted = [
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
      defaultSortOrder: "ascend",
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle  font14px fw-bold">
          EID
        </span>
      ),
      width: 140,

      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Client Name
        </span>
      ),
      width: 140,
      render: (text, record) => (
        <Tip title={record.client_name?.name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name?.name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.client_name?.name > record2.client_name?.name
          ? 1
          : record1.client_name?.name === record2.client_name?.name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Project Name
        </span>
      ),
      width: 120,
      render: (text, record) => <ProjectNameWithDelay record={record} />,
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Scope
        </span>
      ),
      width: 100,
      render: (text, record) => (
        // <span className="text-capitalize     font14px textcolorgreen">{record.scope_name?.scope}</span>
        <Tip title={record.scope_name?.scope}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.scope_name?.scope}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.scope_name?.scope > record2.scope_name?.scope
          ? 1
          : record1.scope_name?.scope === record2.scope_name?.scope
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Program
        </span>
      ),
      width: 90,
      render: (text, record) => (
        // <span className="text-capitalize     font14px textcolorgreen">{record.program_name?.description}</span>
        <Tip title={record.program_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.program_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.program_name?.description >
          record2.program_name?.description
          ? 1
          : record1.program_name?.description ===
            record2.program_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Country
        </span>
      ),
      width: 90,
      render: (text, record) => (
        <Tip title={record.country_name?.description}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country_name?.description}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.country_name?.description >
          record2.country_name?.description
          ? 1
          : record1.country_name?.description ===
            record2.country_name?.description
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let msg = "";
        let color = "black";

        if (record.kickout_status == 1) {
          msg = "kickout Received";
        } else if (
          record.kickout_status == null ||
          record.kickout_status == 0
        ) {
          msg = "Kick out not Started";
        } else if (record.kickout_status == 2) {
          msg = "AT forwarded to TL";
        } else if (record.kickout_status == 3) {
          msg = "TL sent to team members";
        } else if (record.kickout_status == 4) {
          msg = "Sent to Client";
        } else if (record.kickout_status == 5) {
          msg = "Client Resubmitted";
        } else if (record.kickout_status == 6) {
          msg = "TL sent to TR";
        } else if (record.kickout_status == 7) {
          msg = "TR raised clarification in TR Round";
        } else if (record.kickout_status == 8) {
          msg = "TL Sent to team member in TR Round";
        } else if (record.kickout_status == 9) {
          msg = "Team Member forward to TL in TR Round";
        } else if (record.kickout_status == 10) {
          msg = "TL Sent to Client in TR Round";
        } else if (record.kickout_status == 11) {
          msg = "Client Resubmitted in TR Round";
        } else if (record.kickout_status == 12) {
          msg = "TL resubmitted to TR";
        } else if (record.kickout_status == 13) {
          msg = "TR approved";
        } else if (record.kickout_status == 14) {
          msg = "TL allocated CC Reviewer";
        } else if (record.kickout_status == 15) {
          msg = "CC raised clarification";
        } else if (record.kickout_status == 16) {
          msg = "Team Member sent to TL in CC Round";
        } else if (record.kickout_status == 17) {
          msg = "TL forwarded to team members in CC Round";
        } else if (record.kickout_status == 18) {
          msg = "TL forwarded to client in CC Round";
        } else if (record.kickout_status == 19) {
          msg = "Client resubmitted in CC Round";
        } else if (record.kickout_status == 20) {
          msg = "TL forwarded to TR";
        } else if (record.kickout_status == 21) {
          msg = "TR raised clarification in CC Round";
        } else if (record.kickout_status == 22) {
          msg = "TR approved cc round";
        } else if (record.kickout_status == 23) {
          msg = "TL to CC Reviewer";
        } else if (record.kickout_status == 24) {
          msg = "CC Approved";
        } else if (record.kickout_status == 25) {
          msg = "Team Member sent to TL in kickout RFI";
        } else if (record.kickout_status == 26) {
          msg = "TL sent to Team in kickout RFI";
        } else if (record.kickout_status == 27) {
          msg = "TL sent to TM in Kickout RFI";
        } else if (record.kickout_status == 28) {
          msg = "TM raised clarification in kickout RFI";
        } else if (record.kickout_status == 29) {
          msg = "TM approved Kickout RFI";
        } else if (record.kickout_status == 30) {
          msg = "MD raised clarification in kickout RFI";
        } else if (record.kickout_status == 31) {
          msg = "MD Approved";
        } else if (record.kickout_status == 32) {
          msg = "RFI Completed";
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle     font14px fw-bold">
          Action
        </span>
      ),
      key: "x",
      fixed: "right",
      width: 90,
      render: (text, record) => (
        <a className="">
          <EyeOutlined
            onClick={() => editFormForSBUKickout(record.id)}
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
          />
        </a>
      ),
    },
  ];

  const ColumnTeamChange = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "id",
      fixed: "left",
      width: 60,
      render: (id, record, index) => {
        const pageIndex = (pagination.current - 1) * pagination.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EID
        </span>
      ),
      width: 150,

      render: (text, record) => {
        return (
          // <span className="font14px">
          //     {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.earthood_id}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.earthood_id > record2.earthood_id
          ? 1
          : record1.earthood_id === record2.earthood_id
          ? 0
          : -1;
      },
      // defaultSortOrder: 'ascend',
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width: 180,

      render: (text, record) => (
        // <span className="text-capitalize font14px textcolor">{record.project_name}</span>
        <Tip title={record.project_name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>
      ),
      sorter: (record1, record2) => {
        return record1.project_name > record2.project_name
          ? 1
          : record1.project_name === record2.project_name
          ? 0
          : -1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Team Change Status
        </span>
      ),
      width: 150,
      render: (text, record) => {
        let color = "red";
        let msg = "";

        if (record.team_change_status == 0) {
          msg = "Not Started";
        } else if (record.team_change_status == 1) {
          msg = "SBU Initiate the team Change";
        } else if (record.team_change_status == 2) {
          msg = "TL sent to team members";
        } else if (record.team_change_status == 3) {
          msg = "Team members sent to TL";
        } else if (record.team_change_status == 4) {
          msg = "TL sent to TM support";
        } else if (record.team_change_status == 5) {
          msg = "TM support raised the clarification";
        } else if (record.team_change_status == 6) {
          msg = "TM support approved";
        } else if (record.team_change_status == 7) {
          msg = "TM raised the clarification";
        } else if (record.team_change_status == 8) {
          msg = "TM approved";
        }

        return (
          <Tip title={msg}>
            <span
              className="text-capitalize font14px preserve-space text-ellipsis"
              style={{ color }}
            >
              {msg}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return record1.status > record2.status
          ? 1
          : record1.status === record2.status
          ? 0
          : -1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 80,
      render: (record) => (
        <a>
          <EyeOutlined
            onClick={() => handleTeamChange(record.project_id, record.id)}
            style={{ marginRight: "8px", color: "blue", fontSize: "20px" }}
          />
        </a>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={spinloader}>
        <div>
          <div style={{ marginLeft: "0.5rem" }}>
            <span onClick={handletotalprojects}>
              Total Projects:{" "}
              <b style={{ cursor: "pointer", color: "blue" }}>
                {totalProject}{" "}
              </b>{" "}
            </span>
            <br />
            <span>
              Projects pending at Client's end:{" "}
              <b
                onClick={handleClientEndPending}
                style={{ cursor: "pointer", color: "blue" }}
              >
                {clientPendingDoc}
              </b>
            </span>
            <br />
            <span>
              Projects pending at Earthood's end:{" "}
              <b
                onClick={handleEarthoodEndPending}
                style={{ cursor: "pointer", color: "blue" }}
              >
                {earthoodPendingDoc}
              </b>
            </span>
            <br />
          </div>
        </div>
        <div className="container-fluid bg-white">
          <div className="row">
            <div className="col-12">
              <Tabs
                defaultActiveKey="1"
                centered
                activeKey={activeKey}
                onChange={handleTabChange}
              >
                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 1 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        site Visit Requests
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {siteVisitRequest}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileArrowDown}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="1"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className=" bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                site Visit Requests
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold  font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>
                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                              />{" "}
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnSiteVisitRequest}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 2 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        under Clarification
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {underClarification}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleQuestion}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="2"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                under Clarification
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold  font12px">
                                  Scope
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold  font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                              />
                            </div>
                          </div>
                        </div>
                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnUnderClarification}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 3 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        site Visit Approved
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {siteVisitApproved}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="3"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                site Visit Approved
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold  font12px">
                                  Scope
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle  fw-bold  font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  From Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleFromDateChange}
                                  placeholder="From Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  To Recd. Date
                                </label>
                                <DatePicker
                                  onChange={handleToDateChange}
                                  placeholder="To Date"
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                  format={dateFormat}
                                  showTime={false}
                                  disabledDate={disabledFutureDate}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <Button
                                className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                                onClick={handleSearchByDateRange}
                              >
                                Search
                              </Button>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                              />
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnSiteVisitApproved}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 5 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Under Kickout
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">
                          {rfiCompleted}
                        </p>
                        <FontAwesomeIcon
                          icon={faFileArrowDown}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="5"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className=" bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Under Kickout
                              </p>
                            </div>
                          </div>
                          <div className="row gx-3 align-items-center p-2">
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  client Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select client name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleClientNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {clientname.map((client, index) => (
                                    <Option
                                      key={index}
                                      value={client.id}
                                      label={client.name}
                                    >
                                      {client.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="d-grid mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  Scope{" "}
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select scope"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleScopeSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>

                                  {scopeList.map((scope, index) => (
                                    <Option
                                      key={index}
                                      value={scope.id}
                                      label={scope.sector_name}
                                    >
                                      {scope.sector_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  program Name
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select program name"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleProgramNameSearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {programname.map((program, index) => (
                                    <Option
                                      key={index}
                                      value={program.id}
                                      label={program.program_name}
                                    >
                                      {program.program_name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                              <div className="mb-3">
                                <label className="text-capitalize textcolumntitle   fw-bold  font12px">
                                  Country
                                </label>
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder="Select country"
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={handleCountrySearch}
                                  style={{ width: "100%" }}
                                  className="rounded-2"
                                >
                                  <Option value="">Select</Option>
                                  {countryList.map((country, index) => (
                                    <Option
                                      key={index}
                                      value={country.id}
                                      label={country.name}
                                    >
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>

                            <div className="col-sm-4 col-md-3 mt-3">
                              <Input.Search
                                allowClear
                                placeholder="Search By Project Name/EID"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                enterButton
                                onSearch={handleSearch}
                                style={{ width: "100%" }}
                                className="rounded-2 custom-search"
                              />{" "}
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={columnRFICompleted}
                          loading={loader}
                          dataSource={alldata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tab ${
                        activeKey == 7 ? "box_tab" : ""
                      }`}
                    >
                      <p className="font14px textlightblack text-capitalize text-wrap">
                        Team Change
                      </p>
                      <div className="paracenter">
                        <p className="textcolorblue stat_text">{teamChange}</p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </div>
                    </div>
                  }
                  key="7"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                        <div className="bg-white border-0 shadow-sm rounded-top-3">
                          <div className="row border-0">
                            <div className="col-12">
                              <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                                Team Change
                              </p>
                            </div>

                            <div className="col-sm-4 col-md-4 col-lg-3 mb-3 ml-2">
                              <label htmlFor="teamStatus">
                                Team change status
                              </label>
                              <select
                                id="teamStatus"
                                className="form-select"
                                value={teamChangeStatus}
                                onChange={handleTeamChangeStatus}
                              >
                                <option value="">Select</option>
                                <option value="0">Pending</option>
                                <option value="1">Approved</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <Table
                          scroll={{
                            x: 1000,
                          }}
                          columns={ColumnTeamChange}
                          loading={teamChangeLoader}
                          dataSource={teamChangedata}
                          rowKey="proposal_id"
                          pagination={pagination}
                          onChange={handleTableChange}
                          bordered
                        />
                        {alldata != "" ? (
                          <>
                            <LegendTable />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};
export default SBUDash;
