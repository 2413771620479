import './App.css';
import { Route, BrowserRouter , Routes,useLocation } from 'react-router-dom';
import Login from './Auth/Login';
import Dashboard from './Pages/Dashboard';
import Forgot from './Auth/Forgot';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import AddProject from './Pages/AddProject';
import AllProjects from './Components/Proposal/AllProjects';
import PtActions from './Pages/PtActions';
import Clients from '../src/Components/Clients/Clients';
import AddClient from '../src/Components/Clients/AddClient';
import ViewClient from '../src/Components/Clients/ViewClient';
import ClientLogin from './Pages/ClientLogin';
import ThankPage from './Pages/ThankPage';
import ClientLogin1 from './Auth/ClientLogin1';
import CompletedProjectClient from './Pages/CompletedProjectClient';
import ATDeskReview from './Pages/AT_desk_review';
import ClientProjectDetails from './Pages/ClientProjectDetails';
import OngoingProjectClient from './Pages/OngoingProjectClient';
import MainDashboard from './Pages/MainDashboard';
import ProjectDashboard from './Pages/ProjectDashboard';
import KickOutModule from './Pages/KickOutmodule';
import { useEffect, useState } from 'react';
import TotalProject from './Pages/TotalProject';
import TotalProjectsAT from './Pages/TotalProjectsAT';
import TMSActions from './Pages/TMSActions';
import ExcelFile from './Components/Reports/ExcelFile';
import PDFFile from './Components/Reports/PDFFile'
import TeamChangeModule from './Pages/TeamChangeModule';
import RegistrarRFI from './Components/RegistrarRFI';
import TLProposalChange from './Components/TLProposalChange';
import AllNotifications from './Pages/AllNotifications';
import ProgramWiseProjectTable from './Components/Graphs/projectDashboardGraphs/ProjectDashTable/ProgramWiseProjectTable';
import ProjectReport from './Components/Reports/ProjectReport';
import ProposalTable from './Components/Graphs/ProposalTable';
import ClientDashboardGraph from './Pages/ClientDashboardGraphs';
import ATDashboardGraph from './Pages/ATDashboardGraph';
import KickoutTrack from './Components/KickoutTracking/KickoutTrack';
import TAExpertProjects from './Pages/TAExpertProjects';
import FeedbackForm from './Pages/FeedbackForm';
import FeedbackThanksPage from './Pages/FeedbackThanksPage';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {

  const [token , setToken] = useState('')

  return (
    <>
    <div className='wrapper'>  
      
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Login/>}></Route>
        <Route path="/login" element={<Login token={token}/>}></Route>
        <Route path="/dashboard" element={<Dashboard/>}></Route>
        <Route path="/forgotpassword" element={<Forgot/>}></Route>
        <Route path='/clients' element={<Clients/>}></Route>
        <Route path="/projects" element={<AddProject/>}></Route>
        <Route path='/allprojects' element={<AllProjects/>}></Route>
        <Route path='/completedprojects' element={<CompletedProjectClient/>}></Route>
        <Route path='/ptactions' element={<PtActions/>}></Route>
        <Route path='/tmsactions' element={<TMSActions/>}></Route>
        <Route path='/clientlogin/:token' element={<ClientLogin/>}></Route>
        <Route path='/feedback/:token' element={<FeedbackForm/>}></Route>
        <Route path='/clientlogin' element={<ClientLogin1/>}></Route>
        <Route path='/thanks' element={<ThankPage/>}></Route>
        <Route path='/feedbackthanks' element={<FeedbackThanksPage/>}></Route>
        <Route path='/clientprojectdetails/:id' element={<ClientProjectDetails/>}></Route>
        <Route path='/atdeskreview/:id' element={<ATDeskReview/>}></Route>
        <Route path='/ongoingprojects' element={<OngoingProjectClient/>}></Route>
        <Route path='/totalprojects' element={<TotalProject/>}></Route>
        <Route path='/taexpertprojects' element={<TAExpertProjects/>}></Route>

        <Route path='/maindashboard' element={<MainDashboard/>}></Route>
        <Route path='/projectdashboard' element={<ProjectDashboard/>}></Route>
        <Route path='/clientdashboardgraph' element={<ClientDashboardGraph/>}></Route>
        <Route path='/atdashboardgraph' element={<ATDashboardGraph/>}></Route>

        <Route path='/kickoutmodule/:id' element={<KickOutModule/>}></Route>

        <Route path='/kicktrack/:project_id/:kickout_id' element={<KickoutTrack/>}></Route>

        <Route path='/teamchangemodule/:id' element={<TeamChangeModule/>}></Route>
        <Route path='/proposalchangemodule/:id' element={<TLProposalChange/>}></Route>
        <Route path='/totalprojectsat' element={<TotalProjectsAT/>}></Route>
        <Route path='/excelsheet' element={<ExcelFile/>}></Route>
        <Route path='/pdffile' element={<PDFFile/>}></Route>
        <Route path='/rfi' element={<RegistrarRFI/>}></Route>
        <Route path='/addclient' element={<AddClient/>}></Route>
        <Route path='/viewclient' element={<ViewClient/>}></Route>
        <Route path='/viewallnotifications' element={<AllNotifications/>}></Route>

        <Route path='/programwiseprojecttable' element={<ProgramWiseProjectTable/>}></Route>

        <Route path='/reports/projectreport' element={<ProjectReport/>}></Route>
        <Route path='/proposaltable' element={<ProposalTable/>}></Route>
      </Routes>
    </BrowserRouter>
    </div>
    </>
   
  );
}

export default App;